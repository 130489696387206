/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react';
import OrderHistory from '../../components/OrderHistory/OrderHistory';

class OrdersPage extends Component {
	
	render(){
		return(
			<div>
				<OrderHistory
					page={this.props.match.params.page?this.props.match.params.page:1}
				/>
			</div>
		)
	}
}

export default OrdersPage;

