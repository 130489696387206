/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Input from '../UI/Input/Input'
import Preloader from '../Preloader/Preloader'
import Button from '../UI/Button/Button'
import './PassInfo.scss'
import {connect} from 'react-redux'
import {sendPassInfoData} from '../../store/actions/passInfo'

class PassInfo extends Component {

	state = {
		formControls: {},
		success: null,
		error: null
	}


	formValidate(){
		let formValid = true;
		Object.keys(this.state.formControls).map((controlName) => {
			const control = this.state.formControls[controlName]
			if(!control.touched){
				control.valid = this.validateControlError(control.value, control.validation, controlName)?false:true
				control.errorMessage = !!this.validateControlError(control.value, control.validation, controlName)?this.validateControlError(control.value, control.validation, controlName):''
			}
			if(!control.valid){
				formValid = false
			}
			return false
		})
		return formValid
	}

	renderInputs = () => {
		const formControls = Object.keys(this.state.formControls).map((controlName, index) => {
			const control = this.state.formControls[controlName]
			console.log(control.errorMessage)
			return(
				<Input 
					key = {controlName + index}
					type={control.type}
					name={controlName}
					value={control.value.trim()}
					valid={control.valid}
					label={control.label}
					mask={control.mask}
					placeholder={control.placeholder}
					touched={control.touched}
					shouldValidate={!!control.validation}
					required={control.validation.required}
					errorMessage={control.errorMessage}
					onChange={event => this.onChangeHandler(event, controlName)}
				/>
			)
		})
		
		return formControls
	}
	
	renderPassInfo(){
		return(
			<div className="fieldset passinfofields">
				<legend>Изменение пароля</legend>
				{ this.renderInputs() }
			</div>		
		)
	}
	
	savePassInfo = () => {
		let passInfoData  = new URLSearchParams()
		Object.keys(this.state.formControls).map((controlName) => {
			const control = this.state.formControls[controlName]
			passInfoData.append(controlName, control.value)
			return true
		})
		this.props.sendPassInfoData(passInfoData)
		return true
	}

	componentDidMount(){
		console.log('state', this.state)
		this.setState({
			formControls: this.props.formControls,
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps){
		console.log('success', nextProps.success)
		console.log('formControls', nextProps.formControls)
		if(nextProps.success) {
			this.setState({
				formControls: nextProps.formControls,
				success: nextProps.success,
				error: nextProps.error
			})
		} else {
			const formControls = nextProps.formControls
			formControls.password.value = ''
			formControls.passconfirm.value = ''
			this.setState({
				formControls,
				success: nextProps.success,
				error: nextProps.error

			})
		}
	}
	
	validateControlError(value, validation, controlName){

		if(!validation){
			return false
		}

		if(validation.required && value.trim() === ''){
			return "Поле не может быть пустым"
		}
		
		if(validation.minLength && value.length < validation.minLength){
			return "Некорректное значение поля"
		} else if(controlName === 'passconfirm' && value !== this.state.formControls.password.value){
			return "Не совпадает с паролем"
		}
		
		if(validation.maxLength && value.length > validation.maxLength){
			return "Некорректное значение поля"
		}
		
		return false
	}

	onChangeHandler = (event, controlName) => {
		const formControls = { ...this.state.formControls }
		const control = { ...formControls[controlName] }
		
		control.value = event.target.value
		control.touched = true
		control.valid = this.validateControlError(control.value, control.validation, controlName)?false:true
		control.errorMessage = !!this.validateControlError(control.value, control.validation, controlName)?this.validateControlError(control.value, control.validation, controlName):''

		formControls[controlName] = control
		
		this.setState({ formControls })
	}

	render(){
		return(
			<div id="passinfo">
				<form id="form_passinfo" name="formpassinfo" method="post" encType="multipart/form-data" className="form-horizontal">
				{ this.renderPassInfo() }
				{this.props.loading && this.props.length !== 0
						? <Preloader />
						: null
				}
				{this.props.success || this.props.error
					? <spsn className="success-text">
						{this.props.success}
						{this.props.error}
					</spsn>
					:null
				}
				<div className="button-group">
			
					{ this.formValidate() && !this.props.loading
					? <Button 
						label=""
						icon="SaveOutlined"
						classes={['icon-button']}
						onClick ={this.savePassInfo}
					  />
					: null
					}
				  </div>
				</form>
			</div>
		)
	}
}

function mapStateToProps(state){
	return {
		formControls: state.passInfo.formControls,
		loading: state.passInfo.loading,
		error: state.passInfo.error,
		success: state.passInfo.success
	}
}

function mapDispatchToProps(dispatch){
	return {
		sendPassInfoData: passInfoData => dispatch(sendPassInfoData(passInfoData))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PassInfo)






