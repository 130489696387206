/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/

import axios from 'axios'
import {
	FETCH_MAIN_MENU_COUNTS_START,
	FETCH_MAIN_MENU_COUNTS_SUCCESS,
	FETCH_MAIN_MENU_COUNTS_ERROR,
	GET_MAIN_MENU_COUNTS
} from './actionTypes'

export function fetchMainMenuCounts(callback = () => {}){
	return async dispatch => {
		dispatch(fetchMainMenuCountsStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}
		
		try{
			const responceWishlistCount = await axios.get(testUrl + '/index.php?route=account/account/wishlist&app_test=1')
			const responceOrdersCount = await axios.get(testUrl + '/index.php?route=account/account/ordersTotal&app_test=1')
			const responceCartCount = await axios.get(testUrl + '/index.php?route=account/account/cartProductTotal&app_test=1')

			const menu = {
				counts: {
					wishProducts: responceWishlistCount.data.total_n,
					orders: responceOrdersCount.data.total,
					cartTotal: responceCartCount.data.total
				},

				needupdate: false
			}

			dispatch(fetchMainMenuCountsSuccess(menu))
			callback()

		} catch(e){
			console.log(e)
			dispatch(fetchMainMenuCountsError(e))
		}
	}
}

export function fetchMainMenuCountsStart(){
	return {
		type: FETCH_MAIN_MENU_COUNTS_START
	}
}
export function fetchMainMenuCountsSuccess(menu){
	return {
		type: FETCH_MAIN_MENU_COUNTS_SUCCESS,
		counts: menu.counts,
		needupdate: menu.needupdate
	}
}
export function fetchMainMenuCountsError(e){
	return {
		type: FETCH_MAIN_MENU_COUNTS_ERROR,
		error: e
	}
}

export function getMainMenuCounts(){
	return {
		type: GET_MAIN_MENU_COUNTS
	}
}


