/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Input from '../UI/Input/Input'
import Preloader from '../Preloader/Preloader'
import Button from '../UI/Button/Button'
import './MainInfo.scss'
import {connect} from 'react-redux'
import {fetchMainInfo, getMainInfo, sendMainInfoData} from '../../store/actions/mainInfo'

function validateEmail(email) {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|contact)\b/;
	const re2 = /\s+/g
	return (re.test(String(email).toLowerCase()) && !re2.test(String(email)))
}

class MainInfo extends Component {
	
	state = {
		formControls: {}
	}
	

	formValidate(){
		let formValid = true;
		Object.keys(this.state.formControls).map((controlName) => {
			const control = this.state.formControls[controlName]
			if(!control.touched){
				control.valid = this.validateControlError(control.value, control.validation)?false:true
				control.errorMessage = !!this.validateControlError(control.value, control.validation)?this.validateControlError(control.value, control.validation):''	
			}
			if(!control.valid ){
				formValid = false
			}
			return false
		})
		return formValid
	}
	
	formEqualStore(){
		let formEqual = true;
		if(this.state.formControls){
			Object.keys(this.state.formControls).map((controlName) => {
				if(this.props.formControls[controlName] ){
					if(this.props.formControls[controlName].type !== 'tel') {
						if (this.state.formControls[controlName]
							&& this.state.formControls[controlName].touched === true
							&&  this.state.formControls[controlName].value !== this.props.formControls[controlName].value) {
							formEqual = false
						}
					} else {
						if (this.state.formControls[controlName]
							&& this.state.formControls[controlName].touched === true
							&& this.state.formControls[controlName].value.replace(/[^+0-9]/gim,'') !== this.props.formControls[controlName].value.replace(/[^+0-9]/gim,'')) {
							formEqual = false
						}
					}
				}
				return false
			})
		}
		return formEqual
	}
	
	renderInputs = () => {
		const formControls = Object.keys(this.state.formControls).map((controlName, index) => {
			const control = this.state.formControls[controlName]
			return(
				<Input 
					key = {controlName + index}
					type={control.type}
					name={controlName}
					value={control.value}
					valid={control.valid}
					label={control.label}
					mask={control.mask}
					placeholder={control.placeholder}
					touched={control.touched}
					shouldValidate={!!control.validation}
					required={control.validation.required}
					errorMessage={control.errorMessage}
					onChange={event => this.onChangeHandler(event, controlName)}
				/>
			)
		})
		
		return formControls
	}
	
	renderMainInfo(){
		return(
			<div className="fieldset maininfofields">
				<legend>Личные данные</legend>
				{ this.renderInputs() }
			</div>		
		)
	}
	
	saveMainInfo = () => {
		let mainInfoData  = new URLSearchParams()
		Object.keys(this.state.formControls).map((controlName) => {
			const control = this.state.formControls[controlName]
			if(control.type === 'tel'){
				mainInfoData.append(controlName, control.value.replace(/[^+0-9]/gim,''))
			} else {
				mainInfoData.append(controlName, control.value)
			}
			return true
		})
		this.props.sendMainInfoData(mainInfoData)
		return true
	}
	
	getMainInfoData = () => {
		this.props.getMainInfo()
		this.setState({
			formControls: this.props.formControls
		})
		
		return true
	}
	
	componentDidMount(){
		if(this.props.needupdate){
			this.props.fetchMainInfo(()=>{
				this.setState({
					formControls: this.props.formControls
				})
			})
		} else {
			this.getMainInfoData()
		}

	}
	
	validateControlError(value, validation){

		if(!validation){
			return false
		}

		if(validation.email && !validateEmail(value)){
			return "Некорректный E-mail адрес"
		}

		if(validation.required && value.trim() === ''){
			return "Поле не может быть пустым"
		}

		if(validation.tel){
			if((value.indexOf('+3') + 1 && value.replace(/[^+0-9]/gim,'').length < 13) || value.replace(/[^+0-9]/gim,'').length > 13) {
				return "Некорректный номер телефона"
			}
			if(value.replace(/[^+0-9]/gim,'').length < 12 || value.replace(/[^+0-9]/gim,'').length > 13) {
				return "Некорректный номер телефона"
			}
		}
		
		if(validation.minLength && value.length < validation.minLength){
			return "Некорректное значение поля"
		}
		
		if(validation.maxLength && value.length > validation.maxLength){
			return "Некорректное значение поля"
		}
		
		return false
	}

	onChangeHandler = (event, controlName) => {
		const formControls = { ...this.state.formControls }
		const control = { ...formControls[controlName] }
		
		control.value = event.target.value
		control.touched = true
		control.valid = this.validateControlError(control.value, control.validation)?false:true
		control.errorMessage = !!this.validateControlError(control.value, control.validation)?this.validateControlError(control.value, control.validation):''

		formControls[controlName] = control
		
		this.setState({ formControls })
	}

	render(){
		return(
			<div id="maininfo">
				<form id="form_maininfo" name="formmaininfo" method="post" encType="multipart/form-data" className="form-horizontal">
				{ this.renderMainInfo() }
				{
					this.props.loading && this.props.length !== 0
						? <Preloader />
						: null
				}

				<div className="button-group">
					{ !this.formEqualStore() && !this.props.loading
					? <Button 
						label=""
						icon="RotateLeft"
						classes={['icon-button']}
						onClick ={this.getMainInfoData}
					/>
					: null
					}
			
					{ !this.formEqualStore() && this.formValidate() && !this.props.loading
					? <Button 
						label=""
						icon="SaveOutlined"
						classes={['icon-button']}
						onClick ={this.saveMainInfo}
					  />
					: null
					}
				  </div>
				</form>
			</div>
		)
	}
}

function mapStateToProps(state){
	return {
		formControls: state.mainInfo.formControls,
		loading: state.mainInfo.loading,
		error: state.mainInfo.error,
		needupdate: state.mainInfo.needupdate
	}
}

function mapDispatchToProps(dispatch){
	return {
		fetchMainInfo: callback => dispatch(fetchMainInfo(callback)),
		getMainInfo: () => dispatch(getMainInfo()),
		sendMainInfoData: mainInfoData => dispatch(sendMainInfoData(mainInfoData))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo)






