/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React from 'react'
import './Button.scss'
import {
	AddCircleOutline,
	SaveOutlined,
	RotateLeft,
	PersonAdd,
	PhoneIphone,
	VpnKey

} from '@material-ui/icons'

const iconRender = iconName => {
	switch(iconName){
		case 'AddCircleOutline':
			return ( <AddCircleOutline /> )
		case 'SaveOutlined':
			return ( <SaveOutlined /> )
		case 'RotateLeft':
			return ( <RotateLeft /> )
		case 'PersonAdd':
			return ( <PersonAdd /> )
		case 'PhoneIphone':
			return ( <PhoneIphone /> )
		case 'VpnKey':
			return ( <VpnKey /> )
		default:
			return null
	}
}

const Button = props => {
	const buttonType = props.type || 'button'
	const label = props.label
	const cls = props.classes ? props.classes : null
	const icon = props.icon
	
	return (		
		<button 
			type={buttonType}
			id={!props.buttonId ? null : props.buttonId}
			className={cls ? cls.join(' ') : null}
			onClick={props.onClick}
		>
		{iconRender(icon)}
		{label}
		{props.children}
		</button>
	)
}


export default Button;