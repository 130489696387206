/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/
import {
	FETCH_ORDERS_START,
	FETCH_ORDERS_SUCCESS, 
	FETCH_ORDERS_ERROR, 
	GET_ORDERS
} from '../actions/actionTypes'

let initalState = {
	ordersInfo: {},
	loading: false,
	error: null,
	needupdate: true
	
};

export default function ordersReducer(state = initalState, action){
	
	switch(action.type){
		case FETCH_ORDERS_START:
			return{
				...state, loading: true
			}
		case FETCH_ORDERS_SUCCESS:

			initalState = { 
				...state, ordersInfo: action.orderesInfo, needupdate: false
			}
			return{
				...state, loading: false, ordersInfo: action.ordersInfo, needupdate: false
			}
		case FETCH_ORDERS_ERROR:
			return{
				...state, loading: false, error: action.error
			}

		case GET_ORDERS:
			return state
			
		default:
			return state
	}
	
}