/* 
    Created on : 10.12.2019, 06:18:59
    Author     : Andy Kar
*/

export const FETCH_MAIN_INFO_START = 'FETCH_MAIN_INFO_START'
export const FETCH_MAIN_INFO_SUCCESS = 'FETCH_MAIN_INFO_SUCCESS'
export const FETCH_MAIN_INFO_ERROR = 'FETCH_MAIN_INFO_ERROR'
export const SEND_MAIN_INFO_START = 'SEND_MAIN_INFO_START'
export const SEND_MAIN_INFO_SUCCESS = 'SEND_MAIN_INFO_SUCCESS'
export const SEND_MAIN_INFO_ERROR = 'SEND_MAIN_INFO_ERROR'
export const GET_MAIN_INFO = 'GET_MAIN_INFO'

export const SEND_PASS_INFO_START = 'SEND_PASS_INFO_START'
export const SEND_PASS_INFO_SUCCESS = 'SEND_PASS_INFO_SUCCESS'
export const SEND_PASS_INFO_ERROR = 'SEND_PASS_INFO_ERROR'

export const FETCH_ADDRESSES_START = 'FETCH_ADDRESSES_START'
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS'
export const FETCH_ADDRESSES_ERROR = 'FETCH_ADDRESSES_ERROR'
export const GET_ADDRESSES = 'GET_ADDRESSES'
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS'
export const SET_ADDRESS_DEFAULT_SUCCESS = 'SET_ADDRESS_DEFAULT_SUCCESS'
export const SEND_NEW_ADDRESS_START = 'SEND_NEW_ADDRESS_START'
export const SEND_NEW_ADDRESS_ERROR = 'SEND_NEW_ADDRESS_ERROR'
export const SEND_ADDRESS_START = 'SEND_ADDRESS_START'
export const SEND_ADDRESS_ERROR = 'SEND_ADDRESS_ERROR'

export const FETCH_BONUS_START = 'FETCH_BONUS_START'
export const FETCH_BONUS_SUCCESS = 'FETCH_BONUS_SUCCESS'
export const FETCH_BONUS_ERROR = 'FETCH_BONUS_ERROR'
export const GET_BONUS = 'GET_BONUS'

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'
export const GET_ORDERS = 'GET_ORDERS'

export const FETCH_WISH_INFO_START = 'FETCH_WISH_INFO_START'
export const FETCH_WISH_INFO_SUCCESS = 'FETCH_WISH_INFO_SUCCESS'
export const FETCH_WISH_INFO_ERROR = 'FETCH_WISH_INFO_ERROR'
export const GET_WISH_INFO = 'GET_WISH_INFO'

export const FETCH_MAIN_MENU_COUNTS_START = 'FETCH_MAIN_MENU_COUNTS_START'
export const FETCH_MAIN_MENU_COUNTS_SUCCESS = 'FETCH_MAIN_MENU_COUNTS_SUCCESS'
export const FETCH_MAIN_MENU_COUNTS_ERROR = 'FETCH_MAIN_MENU_COUNTS_ERROR'
export const GET_MAIN_MENU_COUNTS = 'GET_MAIN_MENU_COUNTS'

export const FETCH_CHECK_LOGGED_START = 'FETCH_CHECK_LOGGED_START'
export const FETCH_CHECK_LOGGED_SUCCESS = 'FETCH_CHECK_LOGGED_SUCCESS'
export const FETCH_CHECK_LOGGED_ERROR = 'FETCH_CHECK_LOGGED_ERROR'
export const SEND_LOGIN_INFO_START = 'SEND_LOGIN_INFO_START'
export const SEND_LOGIN_INFO_SUCCESS = 'SEND_LOGIN_INFO_SUCCESS'
export const SEND_LOGIN_INFO_ERROR = 'SEND_LOGIN_INFO_ERROR'
export const SEND_RESET_START = 'SEND_RESET_START'
export const SEND_RESET_SUCCESS = 'SEND_RESET_SUCCESS'
export const SEND_RESET_ERROR = 'SEND_RESET_ERROR'