/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react';
import OrderInfo from '../../components/OrderInfo/OrderInfo';

class OrderPage extends Component {
	
	render(){
		return(
			<div>
				<OrderInfo
					id={this.props.match.params.id}
				/>
			</div>
		)
	}
}
export default OrderPage

