/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import LeftMenu from '../../components/Navigation/LeftMenu/LeftMenu'
import Header from '../../components/Header/Header'
import Footer from "../../components/Footer/Footer"
import AuthPage from '../../pages/AuthPage/AuthPage'
import {fetchCheckLogged} from "../../store/actions/auth"
import {connect} from "react-redux"
import MainInfoСheck from "../../components/MainInfoСheck/MainInfoСheck"

const column_left = null
const content_top = null
const content_bottom = null
const column_right = null

class Layout extends Component {

	state = {
		logged: false
	}

	UNSAFE_componentWillMount(){
		const code = window.location.pathname.split('code=')[1];
		if(!this.state.logged) {
			console.log('code', code)
			this.props.fetchCheckLogged(code)
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps){
		const logged = nextProps.logged
		this.setState({ logged } )
	}

	render(){
		return(
			<div className={"body"}>
				<Header />
				<div id="account-account">
					<h1>Личные кабинет</h1>
					<div className="container">
						{ column_left }
						<div id="main_content">
							{ content_top }
							{this.state.logged
								? <LeftMenu/>
								: null
							}
							<div id="content-wrap">
								<div id="content">
									<main>
										{ this.state.logged
											? <React.Fragment>
												{console.log('infoCheck', this.props)}
												{ !this.props.infoCheck && false
													? <MainInfoСheck />
													: this.props.children
												}
												{/*{this.props.children}*/}
											  </React.Fragment>
											: <AuthPage />
										}
									</main>
								</div>
								<div id="preloader-wrap"></div>
							</div>
							{ content_bottom }
						</div>
						{ column_right }
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

function mapStateToProps(state){
	return {
		loading: state.auth.loading,
		error: state.auth.error,
		logged: state.auth.logged,
		infoCheck: state.auth.infoCheck
	}
}

function mapDispatchToProps(dispatch){
	return {
		fetchCheckLogged: (code) => dispatch(fetchCheckLogged(code))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)

