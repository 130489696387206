/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react';
import MainInfo from '../../components/MainInfo/MainInfo';
import AddressList from '../../components/AddressList/AddressList';
import AddressForm from '../../components/AddressForm/AddressForm';
import Bonus from '../../components/Bonus/Bonus';

class MainPage extends Component {
	
	render(){
		return(
			<div>
				<MainInfo data={this.props.accountData}/>
				<AddressList />
				<AddressForm />
				<Bonus
					bonusList={false}
				/>
			</div>
		)
	}
}

export default MainPage;
