/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/
import {SEND_PASS_INFO_START, SEND_PASS_INFO_SUCCESS, SEND_PASS_INFO_ERROR} from '../actions/actionTypes'

let initalState = {
	
	formControls:{
		password:{
			value: '',
			type: 'password',
			label: 'Новый пароль',
			placeholder: '****',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{
				required: true,
				minLength: 4,
				maxLength: 20
			}
		},
		passconfirm:{
			value: '',
			type: 'password',
			label: 'Пароль еще раз',
			placeholder: '****',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{
				required: true,
				minLength: 4,
				maxLength: 20
			}
		}
	},
	error: null,
	success: null
	
}

export default function mainInfoReducer(state = initalState, action){
	
	switch(action.type){

		case SEND_PASS_INFO_START:
			return{
				...state, loading: true
			}
		case SEND_PASS_INFO_SUCCESS:
			return{
				...state,
				loading: false,
				success: action.success,
				error: action.error
			}
		case SEND_PASS_INFO_ERROR:
			return{
				...state, loading: false, error: action.error
			}

		default:
			return state
	}
	
}