/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React from 'react'
import './Input.scss'
import InputMask from 'react-input-mask'

function isInvalid(props){
	return !props.valid && props.shouldValidate && props.touched
}

const Input = props => {
	const InputType = props.type || 'text'
	const cls = []
	const name = props.name
	const htmlFor = 'input-' + name
	const placeholder = props.placeholder
	const errorMessage = props.errorMessage
	const mask = props.mask || null

	cls.push('form-group');
	
	if(props.required){
		cls.push('required');
	}
	
	if(isInvalid(props)){
		cls.push("Invalid");
	}

	function renderInput() {
		if (InputType !== 'tel') {
			if (mask) {
				return (
					<React.Fragment>
						{!props.defaultValue
							? <InputMask mask={mask} maskChar="" value={props.value.trim()} onChange={props.onChange}>
								{() => <input
									type={InputType}
									name={name}
									placeholder={placeholder}
									id={htmlFor}
									onFocus={props.onFocus}
									onKeyDown={props.onKeyDown}
									className="form-control"
									errormessage={props.errorMessage}
								/>
								}
							</InputMask>
							: <InputMask mask={mask} maskChar="" value={props.value.trim()} onChange={props.onChange}>
								{() => <input
									type={InputType}
									name={name}
									defaultValue={props.defaultValue}
									placeholder={placeholder}
									id={htmlFor}
									onChange={props.onChange}
									onKeyDown={props.onKeyDown}
									className="form-control"
									errormessage={props.errorMessage}
								/>
								}
							</InputMask>
						}
					</React.Fragment>
				)
			} else {
				return (
					<React.Fragment>
						{!props.defaultValue
							? <input
								autocomplete={props.autocomplete}
								type={InputType}
								name={name}
								value={props.value}
								onChange={props.onChange}
								onKeyDown={props.onKeyDown}
								placeholder={placeholder}
								id={htmlFor}
								onFocus={props.onFocus}
								className="form-control"
								step={props.step}
								min={props.min}
								max={props.max}
								errormessage={props.errorMessage}
							/>
							: <input
								autocomplete={props.autocomplete}
								type={InputType}
								name={name}
								value={props.value}
								onChange={props.onChange}
								onKeyDown={props.onKeyDown}
								defaultValue={props.defaultValue}
								placeholder={placeholder}
								id={htmlFor}
								step={props.step}
								min={props.min}
								max={props.max}
								className="form-control"
								errormessage={props.errorMessage}
							/>
						}
					</React.Fragment>
				)
			}
		} else {
			let nationalTelMask = "+9 999 999 99 99"
			let newValue = props.value
			if(typeof props !== 'undefined' && typeof props.value !== 'undefined') {
				if (props.value.indexOf('+7') + 1) {
					nationalTelMask = "+9 999 999 99 99"
				} else if (newValue.indexOf('+3') + 1) {
					nationalTelMask = "+999 99 999 99 99"
				} else if (newValue.indexOf('+4') + 1) {
					nationalTelMask = "+99 99 999 99 99"
				} else if (!(newValue.indexOf('+7') + 1) && !(newValue.indexOf('+4') + 1) && !(newValue.indexOf('+3') + 1)) {
					console.log('error')
					nationalTelMask = "+9"
				}
			}
			return (
				<React.Fragment>
					<InputMask
						mask={nationalTelMask}
						maskChar=""
						value={newValue}
						onChange={props.onChange}
					>
						{() => <input
							type={InputType}
							name={name}
							defaultValue={props.defaultValue}
							placeholder={placeholder}
							id={htmlFor}
							onChange={props.onChange}
							onKeyDown={props.onKeyDown}
							className="form-control"
							errormessage={props.errorMessage}
						/>
						}
					</InputMask>
				</React.Fragment>
			)
		}
	}
	
	return (
		<div className={cls.join(' ')}>
			{props.label
				? <label
					className="control-label"
					htmlFor={htmlFor}
				>{props.label}</label>
				: null
			}
			{ renderInput() }
			{
				isInvalid(props) ?
					<div className="text-danger">{errorMessage}</div>
					: null
			}
		</div>
	)
}

export default Input