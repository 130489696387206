/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/

import axios from 'axios'
import API from "../../utils/API"
import {
	FETCH_CHECK_LOGGED_START,
	FETCH_CHECK_LOGGED_SUCCESS,
	FETCH_CHECK_LOGGED_ERROR,
	SEND_LOGIN_INFO_START,
	SEND_LOGIN_INFO_SUCCESS,
	SEND_LOGIN_INFO_ERROR,
	SEND_RESET_START,
	SEND_RESET_SUCCESS,
	SEND_RESET_ERROR
} from './actionTypes'
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";



export function fetchCheckLogged(code='', callback = () => {}){
	return async dispatch => {
		dispatch(fetchCheckLoggedStart())
		console.log('code', code)
		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			if(code) {
				console.log('code', code)
				const responce = await axios.get(testUrl + '/index.php?route=account/account/customerIsLogged&code=' + code)
				console.log(responce)
				const info = {
					logged: responce.data.logged,
					infoCheck: responce.data.infoCheck,
				}
				dispatch(fetchCheckLoggedSuccess(info))
				callback()
			} else {
				const responce = await axios.get(testUrl + '/index.php?route=account/account/customerIsLogged&app_test=1')
				console.log(responce)
				const info = {
					logged: responce.data.logged,
					infoCheck: responce.data.infoCheck,
				}
				dispatch(fetchCheckLoggedSuccess(info))
				callback()
			}
		} catch(e){
			console.log(e)
			dispatch(fetchCheckLoggedError(e))
		}
	}
}

export function sendLoginData(loginType, loginInfoData){
	return async dispatch => {
		dispatch(sendLoginInfoStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		let sendUrl
		switch (loginType) {
			case 'loginPhoneCode':

				sendUrl = testUrl + '/index.php?route=account/account/send_code&app_test=1'

				try{
					const responce = await axios.post(sendUrl, loginInfoData)

					if(responce.status === 200){
						const isLogged = await API.get(testUrl + '/index.php?route=account/account/customerIsLogged&app_test=1')
						console.log(isLogged)
						const info = {
							errorPassLogin: false,
							errorPhoneLogin: responce.data.error_warning?responce.data.error_warning:false,
							errorRegister: false,
							errorReset: false,
							customerId: responce.data.customer_id?responce.data.customer_id:false,
							customerPhone: responce.data.customer_phone?responce.data.customer_phone:null,
							customerEmail: responce.data.customer_email?responce.data.customer_email:null,
							logged: responce.data.customer_id?true:false,
							infoCheck: isLogged.data.infoCheck,
							code:  responce.data.code,
							codeError: responce.data.code_error,
							codeMessage:  responce.data.code_message
						}

						dispatch(sendLoginInfoSuccess(info))

					} else {
						dispatch(sendLoginInfoError({error: "Ошибка связи с сервером"}))
					}
				} catch(e){
					console.log(e)
					dispatch(sendLoginInfoError(e))
				}

				break
			case 'loginPhone':

				sendUrl = testUrl + '/index.php?route=account/account/login_phone&app_test=1'

				try{
					const responce = await axios.post(sendUrl, loginInfoData)

					if(responce.status === 200){
						const isLogged = await axios.get(testUrl + '/index.php?route=account/account/customerIsLogged&app_test=1')
						console.log(isLogged)
						const info = {
							errorPassLogin: false,
							errorPhoneLogin: responce.data.error_warning?responce.data.error_warning:false,
							errorRegister: false,
							errorReset: false,
							customerId: responce.data.customer_id?responce.data.customer_id:false,
							customerPhone: responce.data.customer_phone?responce.data.customer_phone:null,
							customerEmail: responce.data.customer_email?responce.data.customer_email:null,
							logged: responce.data.customer_id?true:false,
							infoCheck: isLogged.data.infoCheck,
							code:  responce.data.code,
							codeError: responce.data.code_error,
							codeMessage:  responce.data.code_message
						}

						dispatch(sendLoginInfoSuccess(info))

					} else {
						dispatch(sendLoginInfoError({error: "Ошибка связи с сервером"}))
					}
				} catch(e){
					console.log(e)
					dispatch(sendLoginInfoError(e))
				}

				break

			case 'loginMail':
				sendUrl = testUrl + '/index.php?route=account/account/pass_login&app_test=1'

				try{
					const responce = await axios.post(sendUrl, loginInfoData)

					if(responce.status === 200){
						const isLogged = await axios.get(testUrl + '/index.php?route=account/account/customerIsLogged&app_test=1')
						console.log(isLogged)
						const info = {
							errorPassLogin: responce.data.error_warning?responce.data.error_warning:false,
							errorPhoneLogin: false,
							errorRegister: false,
							errorReset: false,
							customerId: responce.data.customer_id?responce.data.customer_id:false,
							customerPhone: responce.data.customer_phone?responce.data.customer_phone:null,
							customerEmail: responce.data.customer_email?responce.data.customer_email:null,
							logged: responce.data.customer_id?true:false,
							infoCheck: isLogged.data.infoCheck,


						}

						dispatch(sendLoginInfoSuccess(info))

					} else {
						dispatch(sendLoginInfoError({error: "Ошибка связи с сервером"}))
					}
				} catch(e){
					console.log(e)
					dispatch(sendLoginInfoError(e))
				}

				break
			case 'register':
				sendUrl = testUrl + '/index.php?route=account/account/register&app_test=1'

				try{
					const responce = await axios.post(sendUrl, loginInfoData)

					if(responce.status === 200){
						const isLogged = await axios.get(testUrl + '/index.php?route=account/account/customerIsLogged&app_test=1')
						console.log(isLogged)
						const info = {
							errorPassLogin: false,
							errorPhoneLogin: false,
							errorReset: false,
							errorRegister: responce.data.error_warning?responce.data.error_warning:false,
							customerId: responce.data.customer_id?responce.data.customer_id:false,
							customerPhone: responce.data.customer_phone?responce.data.customer_phone:null,
							customerEmail: responce.data.customer_email?responce.data.customer_email:null,
							logged: responce.data.customer_id?true:false,
							infoCheck: isLogged.data.infoCheck,
						}

						dispatch(sendLoginInfoSuccess(info))

					} else {
						dispatch(sendLoginInfoError({error: "Ошибка связи с сервером"}))
					}
				} catch(e){
					console.log(e)
					dispatch(sendLoginInfoError(e))
				}

				break
			default:
				sendUrl = testUrl + '/index.php?route=account/account/pass_login&app_test=1'
				break
		}


	}
}
export function sendResetData(loginInfoData){
	return async dispatch => {
		dispatch(sendResetStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		let sendUrl
		sendUrl = testUrl + '/index.php?route=account/account/forgotten&app_test=1'

		try{
			const responce = await axios.post(sendUrl, loginInfoData)
			console.log(responce)
			console.log(loginInfoData)
			if(responce.status === 200){
				const info = {
					errorPassLogin: false,
					errorPhoneLogin: false,
					errorReset: responce.data.error_warning?responce.data.error_warning:false,
					errorRegister: false,
					customerId: responce.data.customer_id?responce.data.customer_id:false,
					customerPhone: responce.data.customer_phone?responce.data.customer_phone:null,
					customerEmail: responce.data.customer_email?responce.data.customer_email:null,
					logged: responce.data.customer_id?true:false
				}
				console.log(info.errorReset)
				dispatch(sendResetSuccess(info))

			} else {
				dispatch(sendResetError({error: "Ошибка связи с сервером"}))
			}
		} catch(e){
			console.log(e)
			dispatch(sendResetError(e))
		}
	}
}

export function fetchCheckLoggedStart(){
	return {
		type: FETCH_CHECK_LOGGED_START
	}
}
export function fetchCheckLoggedSuccess(info){
	return {
		type: FETCH_CHECK_LOGGED_SUCCESS,
		logged: info.logged,
		infoCheck: info.infoCheck,
	}
}
export function fetchCheckLoggedError(e){
	return {
		type: FETCH_CHECK_LOGGED_ERROR,
		error: e
	}
}
export function sendLoginInfoStart(){
	return {
		type: SEND_LOGIN_INFO_START
	}
}
export function sendLoginInfoSuccess(info){
	return {
		type: SEND_LOGIN_INFO_SUCCESS,
		logged: info.logged,
		errorPassLogin: info.errorPassLogin,
		errorPhoneLogin: info.errorPhoneLogin,
		errorRegister: info.errorRegister,
		errorReset: info.errorReset,
		customerId: info.customerId,
		customerEmail: info.customerEmail,
		customerPhone: info.customerPhone,
		code: info.code,
		codeError: info.codeError,
		codeMessage: info.codeMessage,
		infoCheck: info.infoCheck
	}
}
export function sendLoginInfoError(e){
	return {
		type: SEND_LOGIN_INFO_ERROR,
		error: e
	}
}

export function sendResetStart(){
	return {
		type: SEND_RESET_START
	}
}
export function sendResetSuccess(info){
	console.log(info.errorReset)
	return {
		type: SEND_RESET_SUCCESS,
		logged: info.logged,
		errorPassLogin: info.errorPassLogin,
		errorPhoneLogin: info.errorPhoneLogin,
		errorRegister: info.errorRegister,
		errorReset: info.errorReset,
		customerId: info.customerId,
		customerEmail: info.customerEmail,
		infoCheck: info.infoCheck
	}
}
export function sendResetError(e){
	return {
		type: SEND_RESET_ERROR,
		error: e
	}
}