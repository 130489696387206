/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Preloader from '../Preloader/Preloader'
import Pagination from '../Pagination/Pagination'
import './Bonus.scss'
import {connect} from 'react-redux'
import {fetchBonus, getBonus} from '../../store/actions/bonus'
import {withRouter} from 'react-router-dom'

class Bonus extends Component {
	
	componentDidMount(){
		if(this.props.needupdate){
			this.props.fetchBonus(this.props.match.params.page)
		} else {
			this.props.getBonus()
		}
	}
	
	renderBonusLevels = () => {
		const data = this.props.bonusInfo
		if(data.rewards_levels){
			return	Object.keys(data.rewards_levels.values).map((index) => {
				const discount = data.rewards_levels.values[index]
				const userLevel = parseInt(data.user_level.reward_level)
				if(parseInt(index) === userLevel){
					return (
						<div key={'customer_group_item' + index} className="customer_group_item active_item">
							<div className="drop"><span className="discount-value">{discount}%</span></div>
							<span className="discount-level-value">{parseInt(index)?data.rewards_levels.levels[parseInt(index) - 1]:0} руб.</span>
							<span className="desctop-block next_step">еще бы {data.user_level.next_level_diff} руб.</span>
						</div>
					)
				} else {
					return (
						<div key={'customer_group_item' + index} className="customer_group_item">
							<div className="drop"><span className="discount-value">{discount}%</span></div>
							<span className="discount-level-value">{parseInt(index)?data.rewards_levels.levels[parseInt(index) - 1]:0} руб.</span>
						</div>
					)
				}

			})
		}
	}

	renderBonusInfo = () => {
		const bonusInfo = this.props.bonusInfo

		let title = "Бонусный счет"
		if (this.props.bonusList) {
			title = "Бонусная программа"
		}

		let level_width = 0
		let lewel_weight = 25
		if (bonusInfo.user_level) {
			level_width = level_width + (bonusInfo.user_level.reward_level * lewel_weight)
			if (bonusInfo.user_level.reward_level > 0 && bonusInfo.user_level.reward_level < 4){
				level_width = level_width + (bonusInfo.rewards_levels.levels[bonusInfo.user_level.reward_level] - bonusInfo.rewards_levels.levels[bonusInfo.user_level.reward_level - 1] - bonusInfo.user_level.next_level_diff) / (bonusInfo.rewards_levels.levels[bonusInfo.user_level.reward_level] - bonusInfo.rewards_levels.levels[bonusInfo.user_level.reward_level - 1]) * lewel_weight
			} else if(bonusInfo.user_level.reward_level === 0){
				level_width = level_width + (bonusInfo.rewards_levels.levels[bonusInfo.user_level.reward_level] - bonusInfo.user_level.next_level_diff) / (bonusInfo.rewards_levels.levels[bonusInfo.user_level.reward_level]) * lewel_weight
			}
		}

		return (
			<div id="cashback">
				<h2 className="cashback-title">{ title }</h2>
				<p>Сумма заказов учитывается за последние { bonusInfo.bonus_days } дней</p>
				<div className="bonus-info-wrap">
					<div className="account-points bonus-info"><h4>На вашем счете</h4>
						<p className="account-points-value bonus-value">
						<span className="bonus-icon"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)"><path d="M12.5 4.54167C12.0417 4.54167 11.6667 4.91667 11.6667 5.375V7.04167H10C9.54167 7.04167 9.16667 7.41667 9.16667 7.875C9.16667 8.33333 9.54167 8.70833 10 8.70833H11.6667V10.375C11.6667 10.8333 12.0417 11.2083 12.5 11.2083C12.9583 11.2083 13.3333 10.8333 13.3333 10.375V8.70833H15C15.4583 8.70833 15.8333 8.33333 15.8333 7.875C15.8333 7.41667 15.4583 7.04167 15 7.04167H13.3333V5.375C13.3333 4.91667 12.9583 4.54167 12.5 4.54167ZM1.66667 7.875C1.66667 5.725 2.83333 3.85 4.56667 2.84167C4.83333 2.68333 5.00833 2.41667 5.00833 2.10833C5.00833 1.46667 4.30833 1.06667 3.75 1.39167C1.51667 2.69167 0 5.10833 0 7.875C0 10.6417 1.51667 13.0583 3.75 14.3583C4.30833 14.6833 5.00833 14.2833 5.00833 13.6417C5.00833 13.3333 4.83333 13.0667 4.56667 12.9083C3.68457 12.3971 2.95233 11.663 2.44335 10.7796C1.93438 9.8962 1.66653 8.89454 1.66667 7.875ZM12.5 0.375C8.36667 0.375 5 3.74167 5 7.875C5 12.0083 8.36667 15.375 12.5 15.375C16.6333 15.375 20 12.0083 20 7.875C20 3.74167 16.6333 0.375 12.5 0.375ZM12.5 13.7083C9.28333 13.7083 6.66667 11.0917 6.66667 7.875C6.66667 4.65833 9.28333 2.04167 12.5 2.04167C15.7167 2.04167 18.3333 4.65833 18.3333 7.875C18.3333 11.0917 15.7167 13.7083 12.5 13.7083Z" fill="#4F9E34"/></g><defs><clipPath id="clip0"><rect y="0.375" width="20" height="15" fill="white"/></clipPath></defs></svg></span>
						{typeof bonusInfo.user_level !== 'undefined' && bonusInfo.user_level.rewards?bonusInfo.user_level.rewards:0} баллов</p>
					</div>
					<div className="account-awaiting-points bonus-info"><h4>Ожидают подтверждения</h4>
						<p className="account-awaiting-points-value bonus-value">
						<span className="bonus-icon"><svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)"><path d="M7.49987 14.2386C11.0144 14.2386 13.8635 11.3895 13.8635 7.875C13.8635 4.36046 11.0144 1.51137 7.49987 1.51137C3.98533 1.51137 1.13623 4.36046 1.13623 7.875C1.13623 11.3895 3.98533 14.2386 7.49987 14.2386Z" stroke="#4F9E34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M7.5 4.23865V7.87501L9.31818 9.69319" stroke="#4F9E34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></g><defs><clipPath id="clip0"><rect y="0.375" width="15" height="15" fill="white"/></clipPath></defs></svg></span>
						{typeof bonusInfo.user_level !== 'undefined' && bonusInfo.user_level.awaiting?bonusInfo.user_level.awaiting:0} баллов</p>
					</div>
					<div className="bonus-separator"></div>
					<div className="account-discount bonus-info"><h4>Ваша скидка</h4>
						<p className="account-discount-value bonus-value">
						<span className="bonus-icon"><img src={require('../../image/icons/discount.png')} alt="" /></span>
						{typeof bonusInfo.user_level !== 'undefined' && bonusInfo.user_level.discount?bonusInfo.user_level.discount:0}%</p>
					</div>
				</div>
				{ parseFloat(bonusInfo.expired_points) !== 0
					? <p style={{color: 'red', marginBottom: '40px'}}>Внимание! У вас будут аннулированы { bonusInfo.expired_points } балла(ов), если Вы не воспользуетесь ими до { bonusInfo.expired_date }</p>
					: null
				}
				<h3>Возвращаем <span><b>{ typeof bonusInfo.user_level !== 'undefined' && bonusInfo.user_level.discount?bonusInfo.user_level.discount:0 }%</b></span> с каждой покупки<span className="discount-separator"></span><span className="ballcurs"><span>1 <b>балл</b></span><span className="rub"> = <b>1</b> руб.</span></span></h3>
				<div className="mobile-block">
					<div className="bonus-account-info" style={{ borderBottom: '1px solid #F5F5F5', marginBottom: 1 + 'px' }}>
						<span className="bonus-info-title">На вашем счете</span><span className="bonus-info-value green"><b>{ typeof bonusInfo.user_level !== 'undefined'?bonusInfo.user_level.total:0 } </b>руб.</span>
					</div>
					<div className="bonus-account-info" style={{ marginBottom: 25 }}>
						<span className="bonus-info-title">До следующего уровня</span><span className="bonus-info-value"><b>{ typeof bonusInfo.user_level !== 'undefined'?bonusInfo.user_level.next_level_diff:0 } </b>руб.</span>
					</div>
				</div>
				<div className="bonus_visual_block_wrap">
					<div className="bonus_visual_block">
						<div className="bonus_visual_level"><div className="bonus_level" style={level_width?{width: level_width + '%'}:{width: '0%'}}></div></div>
						{this.renderBonusLevels()}
						<span className="desctop-block summa"><b>{typeof bonusInfo.user_level !== 'undefined'?bonusInfo.user_level.total:0}</b> руб.</span>
					</div>
				</div>
			</div>
		)
	}
	
	renderBonusListTable = () => {
		const data = this.props.bonusInfo
		let color = ""
		if(data && data.rewards){
			return	Object.keys(data.rewards).map((index) => { 
				const reward = data.rewards[index]
				reward.status = 'начислены'
				if(reward.status === 'начислены'){ color = 'green' }
				return (
					<div key={'reward-tr-' + index} className="tr">
						<div className="table-cell date-cell">
							<span className="reward-info-title mobile-block">Дата начисления</span><span className="date_added">{ reward.date_added }</span>
						</div>
						<div className="table-cell description-cell">
							<span className="reward-info-title mobile-block">Начислены за:</span><span className="description">{ reward.description }</span>
							{ reward.order_method === 'grushevka'
								? <span className="order-icon">
										<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
											<g>
												<path d="M488.468,448.903h-8.532V226.4C499.202,214.003,512,192.386,512,167.822c0-1.305-0.341-2.588-0.988-3.721L451.499,59.953    c-4.18-7.313-12.009-11.857-20.432-11.857H80.933c-8.423,0-16.252,4.543-20.432,11.857L0.988,164.101    C0.341,165.234,0,166.518,0,167.822c0,24.564,12.798,46.181,32.064,58.578v222.503h-8.532c-4.142,0-7.5,3.358-7.5,7.5    c0,4.142,3.358,7.5,7.5,7.5h464.936c4.143,0,7.5-3.358,7.5-7.5C495.968,452.261,492.61,448.903,488.468,448.903z M15.517,175.322    h24.044c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H20.424l53.101-92.927c1.516-2.652,4.354-4.299,7.408-4.299h350.134    c3.054,0,5.893,1.647,7.408,4.299l53.1,92.927h-19.141c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h24.048    c-3.667,26.584-26.532,47.125-54.108,47.125c-27.575,0-50.429-20.543-54.097-47.125h52.096c4.143,0,7.5-3.358,7.5-7.5    c0-4.142-3.357-7.5-7.5-7.5H71.631c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h52.091    c-3.668,26.582-26.523,47.125-54.097,47.125C42.049,222.447,19.184,201.906,15.517,175.322z M372.222,175.322    c-3.668,26.582-26.523,47.125-54.097,47.125c-27.575,0-50.429-20.543-54.097-47.125H372.222z M247.972,175.322    c-3.668,26.582-26.523,47.125-54.097,47.125c-27.574,0-50.429-20.543-54.097-47.125H247.972z M424.854,448.904h-81.193v-25.081    h81.193V448.904z M424.854,408.823h-81.193V271.516h81.193V408.823z M464.936,448.904h-25.081V264.016c0-4.142-3.357-7.5-7.5-7.5    h-96.193c-4.143,0-7.5,3.358-7.5,7.5v184.887H47.064V233.674c7.081,2.433,14.665,3.773,22.561,3.773    c27.095,0,50.624-15.556,62.125-38.207c11.501,22.65,35.03,38.207,62.125,38.207c27.095,0,50.624-15.556,62.125-38.207    c11.501,22.65,35.03,38.207,62.125,38.207c27.095,0,50.624-15.556,62.125-38.207c11.501,22.65,35.03,38.207,62.125,38.207    c7.896,0,15.48-1.34,22.561-3.772V448.904z"/>
											</g>
											<g>
												<path d="M296.081,256.516H79.645c-4.142,0-7.5,3.358-7.5,7.5v152.307c0,4.142,3.358,7.5,7.5,7.5h216.436    c4.143,0,7.5-3.358,7.5-7.5V264.016C303.581,259.873,300.224,256.516,296.081,256.516z M288.581,408.823H87.145V271.516h201.436    V408.823z"/>
											</g>
										</svg>
									</span>
								: null
							}
						</div>
						<div className="table-cell status-cell">
							<span className="reward-info-title mobile-block">Статус</span><span className={'status ' + color }>{ reward.status }</span>
						</div>
						<div className="table-cell total-cell">
							<span className="reward-info-title mobile-block">Всего баллов</span><span className="total">{ reward.points }</span>
						</div>
					</div>
				)
			})
		}
	}
	
	changePage(page){
		this.props.fetchBonus(page)
	}
	
	renderBonusList(){
		const data = this.props.bonusInfo
		if (data && data.rewards){
			return (
				<div>
					<div id="rewards-table-wrap" className="reward-wrap">
						<div className="rewards-title-wrap">
							<h2 className="cashback-title">История начислений</h2>
						</div>
						<div id="rewards-table">
							<div className="rewards-table">
								<div className="theader desctop-block">
									<div className="th">
										<div className="table-cell date-cell">
											<span className="date_added">Дата начисления</span>
										</div>
										<div className="table-cell description-cell">
											<span className="description">Начислены за:</span>
										</div>
										<div className="table-cell status-cell">
											<span className="status">Статус</span>
										</div>
										<div className="table-cell total-cell">
											<span className="total">Всего баллов</span>
										</div>
									</div>
								</div>
								<div className="tbody">
									{ this.renderBonusListTable() }
								</div>				
							</div>
						</div>
					</div>
					<Pagination 
						total={data.total_rewards}
						currentPage={this.props.page}
						limit={10}
						changePage={this.changePage.bind(this)}
						base="/bonuses"
					/>
				</div>
			)
		} else {
			
			return (
				<p>{ data.text_empty }</p>
			)
		}
	}
	
	
	render(){

		return(
			<div>
				{ !this.props.bonusInfo.needupdate
					? this.renderBonusInfo( )
					: <Preloader />
				}
				
				{ this.props.bonusList && !this.props.bonusInfo.needupdate
					? this.renderBonusList()
					: null
				}
			</div>
		)
	}
}

function mapStateToProps(state){
	return {
		bonusInfo: state.bonus.bonusInfo,
		loading: state.bonus.loading,
		error: state.bonus.error,
		needupdate: state.bonus.needupdate
	}
}

function mapDispatchToProps(dispatch){
	return {
		fetchBonus: page => dispatch(fetchBonus(page)),
		getBonus: () => dispatch(getBonus())
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bonus))






