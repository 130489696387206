/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React from 'react';
import './Preloader.scss';

const Preloader = () => {
	return (
		<div id="ajax-preloader">
			<div className="lds-roller">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default Preloader;

