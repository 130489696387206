/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Bonus from '../../components/Bonus/Bonus'

class BonusPage extends Component {
	
	render(){
		return(
			<div>
				<Bonus
					page={this.props.match.params.page?this.props.match.params.page:1}
					bonusList={true}

				/>
			</div>
		)
	}
}

export default BonusPage;

