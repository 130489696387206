/*
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Input from '../UI/Input/Input'
//import PhoneInput from '../UI/PhoneInput/PhoneInput'
//import Preloader from '../Preloader/Preloader'
import Button from '../UI/Button/Button'
import './Auth.scss'
import {connect} from 'react-redux'
import {fetchCheckLogged, sendLoginData, sendResetData} from '../../store/actions/auth'
import {withRouter} from "react-router-dom"

function validateEmail(email) {
	var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|contact)\b/;
	const re2 = /\s+/g
	return (re.test(String(email).toLowerCase()) && !re2.test(String(email)))
}

//function validatePhonenumber(tel) {
//  var ph = /^ ((\ + \ d {1,3} (- |)? \ (? \ d \)? (- |)? \ d {1,3}) | (\ (? \ d {2,3} \)?)) (- |)? (\ d {3,4}) (- |)? (\ d {4}) ((x | ext) \ d {1,5}) {0,1} $/;
//  return ph.test(String(tel).toLowerCase());
//}

class Auth extends Component {

	state = {
		formControls: {},
		currentOption:'loginPhone',
		error: null,
		errorPassLogin: null,
		errorPhoneLogin: null,
		errorRegister: null,
		errorReset: null,
		customerId: null,
		customerEmail: null,
		customerPhone: null
	}

	formValidate(){
		let formValid = true;
		Object.keys(this.state.formControls).map((controlName) => {
			const control = this.state.formControls[controlName]
			if(!control.touched){
				control.valid = this.validateControlError(control.value, control.validation)?false:true
				control.errorMessage = !!this.validateControlError(control.value, control.validation)?this.validateControlError(control.value, control.validation):''
			}
			if(!control.valid ){
				formValid = false
			}
			return false
		})
		return formValid
	}

	renderInput = (controlName, control) => {

		return(
			<Input
				type={control.type}
				name={controlName}
				value={control.value.trim()}
				valid={control.valid}
				label={control.label}
				mask={control.mask}
				placeholder={control.placeholder}
				touched={control.touched}
				shouldValidate={!!control.validation}
				required={control.validation.required}
				errorMessage={control.errorMessage}
				onChange={event => this.onChangeHandler(event, controlName)}
				onKeyDown = {event => this.sendOnEnterClick(event)}
			/>
		)
	}

	renderInputs = (controls) => {

		const formControls = Object.keys(controls).map((controlName, index) => {
			const control = controls[controlName]
			return(
				<Input
					key = {controlName + index}
					type={control.type}
					name={controlName}
					value={control.value.trim()}
					valid={control.valid}
					label={control.label}
					mask={control.mask}
					placeholder={control.placeholder}
					touched={control.touched}
					shouldValidate={!!control.validation}
					required={control.validation.required}
					errorMessage={control.errorMessage}
					onChange={event => this.onChangeHandler(event, controlName)}
					onKeyDown = {event => this.sendOnEnterClick(event)}
				/>
			)
		})

		return formControls
	}

	showCurrentOptionForm(optionName){
		this.setState({currentOption: optionName})
	}

	renderLoginOption(optionName){
		let active = ''
		if(optionName === this.state.currentOption){
			active = ' active'
		}

		return(
			<div className={'loginOption'}>
				<Button
					label={ this.props.formControls[optionName].label }
					icon={ this.props.formControls[optionName].icon }
					classes={['login-option-button' + active]}
					onClick = { this.showCurrentOptionForm.bind(this, optionName) }
				/>
			</div>
		)
	}

	sendOnEnterClick = event =>{
		if(event.keyCode === 13){
			if(this.formValidate() && this.state.currentOption === 'loginPhone' && !this.props.code && this.state.formControls[this.state.currentOption].controls['telephone'].touched && this.state.formControls[this.state.currentOption].controls['telephone'].valid){
				this.sendLoginInfo('loginPhoneCode')
			} else if(this.formValidate() && this.state.currentOption === 'loginPhone' && this.props.code){
				this.sendLoginInfo('loginPhone')
			} else if(this.formValidate() && this.state.currentOption === 'loginMail'){
				this.sendLoginInfo('loginMail')
			}
		}
		return true
	}

	sendLoginInfo = (optionName = this.state.currentOption) => {
		let loginData = new URLSearchParams()
		Object.keys(this.state.formControls[this.state.currentOption].controls).map((controlName) => {
			const control = this.state.formControls[this.state.currentOption].controls[controlName]
			if(control.type === 'tel'){
				loginData.append(controlName, control.value.replace(/[^+0-9]/gim,''))
			} else {
				loginData.append(controlName, control.value)
			}
			return true
		})
		this.props.sendLoginData(optionName, loginData)
		return true
	}

	sendResetInfo = (optionName = this.state.currentOption) => {
		let resetData = new URLSearchParams()
		Object.keys(this.state.formControls[this.state.currentOption].controls).map((controlName) => {
			const control = this.state.formControls[this.state.currentOption].controls[controlName]
			resetData.append(controlName, control.value)
			return true
		})
		this.props.sendResetData(resetData)
		return true
	}

	registerWithMailOrPhone(){
		const formControls = {...this.state.formControls}

		if(this.props.customerEmail) {
			const email = this.props.customerEmail
			formControls['register'].controls['email'].value = email
			formControls['register'].controls['email'].touched = true
		}

		if(this.props.customerPhone){
			const phone = this.props.customerPhone
			formControls['register'].controls['telephone'].value = phone
			formControls['register'].controls['telephone'].touched = true
		}

		this.setState({
			currentOption: 'register',
			customerPhone: null,
			formControls
		})
	}

	renderCurrentOptionForm(){
		switch(this.state.currentOption) {
			case 'loginPhone':
				return this.renderPhoneLoginForm()
			case 'loginMail':
				return this.renderPassLoginForm()
			case 'register':
				return this.renderRegisterForm()
			default:
				return this.renderPassLoginForm()
		}
	}

	renderPhoneLoginForm(){
		return (
			<React.Fragment>
				{ !this.props.code
					? <React.Fragment>
						<div className={'fieldset ' + this.state.currentOption }>
							<legend>{ this.state.formControls[this.state.currentOption].label }</legend>
							{ this.renderInput('telephone', this.state.formControls[this.state.currentOption].controls['telephone']) }
						</div>
						{ this.state.errorPhoneLogin
							? <div className={'fieldset warning'}>
								{ this.state.errorPhoneLogin }
							</div>
							: null }
						{ this.state.codeError
							? <div className={'fieldset warning'}>
								{ this.state.codeError }
							</div>
							: null }
						{this.state.formControls[this.state.currentOption].controls['telephone'].touched && this.state.formControls[this.state.currentOption].controls['telephone'].valid
							? <div className={'fieldset'}>
								{!this.state.customerPhone
									? <div className={'fieldset'}>
										<Button
											label={this.state.formControls[this.state.currentOption].sendButtonCode}
											classes={['send-button']}
											onClick={() => this.sendLoginInfo('loginPhoneCode')}
										/>
									</div>
									: <div className={'fieldset'}>
										<Button
											label={this.state.formControls[this.state.currentOption].buttonRegister}
											classes={['send-button']}
											onClick={() => this.registerWithMailOrPhone()}
										/>
									</div>
								}
							</div>
							: null
						}
					</React.Fragment>
					: <React.Fragment>
						<div className={'fieldset ' + this.state.currentOption }>
							<legend>{ this.state.formControls[this.state.currentOption].label }</legend>
							{ this.state.codeMessage
								? <div className={'fieldset message'}>
									{ this.state.codeMessage }
								</div>
								: null }
							{ this.renderInput('code', this.state.formControls[this.state.currentOption].controls['code']) }
						</div>
						{ this.formValidate()
							? <div className={'fieldset'}>
								<Button
									label={this.state.formControls[this.state.currentOption].sendButtonLogin}
									classes={['send-button']}
									onClick={() => this.sendLoginInfo()}
								/>
							</div>
							:null
						}
					</React.Fragment>
				}
			</React.Fragment>
		)
	}

	renderPassLoginForm(){

		return (
			<React.Fragment>
				<div className={'fieldset ' + this.state.currentOption }>
					<legend>{ this.state.formControls[this.state.currentOption].label }</legend>
					{ this.renderInputs(this.state.formControls[this.state.currentOption].controls) }
				</div>
				{ this.state.errorPassLogin
					? <div className={'fieldset warning'}>
						{ this.state.errorPassLogin }
					</div>
					: null }
				{ this.state.errorReset
					? <div className={'fieldset warning'}>
						{ this.state.errorReset }
					</div>
					: null }
				<div className={'fieldset'}>
					<Button
						label={this.state.formControls[this.state.currentOption].sendButtonReset}
						classes={['reset-button']}
						onClick={() => this.sendResetInfo()}
					/>
				</div>
				{this.formValidate()
					? <React.Fragment>
						{!this.state.customerEmail
							? <div className={'fieldset'}>
								<Button
									label={this.state.formControls[this.state.currentOption].sendButtonLogin}
									classes={['send-button']}
									onClick={() => this.sendLoginInfo()}
								/>
							</div>
							: <div className={'fieldset'}>
								<Button
									label={this.state.formControls[this.state.currentOption].buttonRegister}
									classes={['send-button']}
									onClick={() => this.registerWithMailOrPhone()}
								/>
							</div>
						}
					</React.Fragment>
					: null
				}
			</React.Fragment>
		)
	}

	renderRegisterForm(){
		return (
			<React.Fragment>
				<div className={'fieldset ' + this.state.currentOption }>
					<legend>{ this.state.formControls[this.state.currentOption].label }</legend>
					{ this.renderInputs(this.state.formControls[this.state.currentOption].controls) }
				</div>
				{ this.state.errorRegister
					? <div className={'fieldset warning'}>
						{ this.props.errorRegister }
					</div>
					: null }
				{ this.formValidate()
					? <div className={'fieldset'}>
						<Button
							label={this.state.formControls[this.state.currentOption].buttonRegister}
							classes={['send-button']}
							onClick={() => this.sendLoginInfo()}
						/>
					</div>
					: null
				}
			</React.Fragment>
		)
	}

	UNSAFE_componentWillReceiveProps(nextProps){
		console.log('4', nextProps)
		console.log('4', nextProps.errorReset)
		const error = nextProps.error
		const logged = nextProps.logged
		const customerEmail = nextProps.customerEmail
		const customerPhone = nextProps.customerPhone
		const errorPhoneLogin = nextProps.errorPhoneLogin
		const errorPassLogin = nextProps.errorPassLogin
		const errorRegister = nextProps.errorRegister
		const errorReset = nextProps.errorReset

		this.setState({ error, logged, customerEmail, customerPhone, errorPhoneLogin, errorPassLogin, errorRegister, errorReset } )
	}

	componentDidMount(){

		this.setState({
			formControls: this.props.formControls
		})
	}

	// formValidate(){
	// 	let formValid = true;
	// 	Object.keys(this.state.formControls[this.state.currentOption].controls).map((controlName) => {
	// 		const control = this.state.formControls[this.state.currentOption].controls[controlName]
	// 		if(!control.touched){
	// 			control.valid = this.validateControlError(control.value, control.validation)?false:true
	// 			control.errorMessage = !!this.validateControlError(control.value, control.validation)?this.validateControlError(control.value, control.validation):''
	// 		}
	// 		if(!control.valid ){
	// 			formValid = false
	// 		}
	// 		return false
	// 	})
	// 	return formValid
	// }

	validateControlError(value, validation){

		if(!validation){
			return false
		}

		if(validation.required && value.trim() === ''){
			return "Поле не может быть пустым"
		}

		if(validation.email){

			if(this.state.customerEmail && this.state.errorPassLogin) {
				const customerEmail = null
				const errorPassLogin = null
				this.setState({customerEmail, errorPassLogin})
			}

			if(!validateEmail(value)) {
				return "Некорректный E-mail адрес"
			}
		}

		if(validation.tel){
			if(this.state.customerPhone && this.state.errorPhoneLogin) {
				const customerPhone = null
				const errorPhoneLogin = null
				this.setState({customerPhone, errorPhoneLogin})
			}

			if((value.indexOf('+3') + 1 && value.replace(/[^+0-9]/gim,'').length < 13) || value.replace(/[^+0-9]/gim,'').length > 13) {
				return "Некорректный номер телефона"
			}
			if(value.replace(/[^+0-9]/gim,'').length < 12 || value.replace(/[^+0-9]/gim,'').length > 13) {
				return "Некорректный номер телефона"
			}
		}

		if(validation.passconfirm){
			if(this.state.formControls[this.state.currentOption].controls['password']) {
				const pass = this.state.formControls[this.state.currentOption].controls['password'].value
				if (value !== pass) {
					return "Не совпадает с паролем"
				}
			}
		}

		if(validation.minLength && value.length < validation.minLength){
			return "Количество символов меньше необходимого"
		}

		if(validation.maxLength && value.length > validation.maxLength){
			return "Количество символов больше необходимого"
		}

		return false
	}

	onChangeHandler = (event, controlName) => {
		const formControls = { ...this.state.formControls }
		const control = { ...formControls[this.state.currentOption].controls[controlName] }

		control.value = event.target.value
		control.touched = true
		control.valid = this.validateControlError(control.value, control.validation)?false:true
		control.errorMessage = !!this.validateControlError(control.value, control.validation)?this.validateControlError(control.value, control.validation):''
		formControls[this.state.currentOption].controls[controlName] = control

		if(this.state.errorPassLogin || this.state.errorPhoneLogin || this.state.errorRegister ) {
			const errorPassLogin = null
			const errorPhoneLogin = null
			const errorRegister = null
			this.setState({ errorPassLogin, errorPhoneLogin, errorRegister })
		}

		this.setState({ formControls })
	}

	render(){
		return(
			<React.Fragment>
				{ this.props.logged || this.props.loading
					? null
					: <div id="login_form">
						<div className={'loginOptions'}>
							{ this.renderLoginOption('loginPhone') }
							{ this.renderLoginOption('loginMail') }
							{ this.renderLoginOption('register') }
						</div>
						<div className={'currentOptionForm'}>
							{ this.renderCurrentOptionForm() }
						</div>
					</div>
				}
			</React.Fragment>
		)
	}
}

function mapStateToProps(state){
	return {
		formControls: state.auth.formControls,
		loading: state.auth.loading,
		error: state.auth.error,
		customerId: state.auth.customerId,
		customerEmail: state.auth.customerEmail,
		customerPhone: state.auth.customerPhone,
		errorPassLogin: state.auth.errorPassLogin,
		errorReset: state.auth.errorReset,
		errorPhoneLogin: state.auth.errorPhoneLogin,
		errorRegister: state.auth.errorRegister,
		logged: state.auth.logged,
		code: state.auth.code,
		codeError: state.auth.codeError,
		codeMessage: state.auth.codeMessage

	}
}

function mapDispatchToProps(dispatch){
	return {
		fetchCheckLogged: callback => dispatch(fetchCheckLogged(callback)),
		sendLoginData: (currentOption, loginData) => dispatch(sendLoginData(currentOption, loginData)),
		sendResetData: (resetData) => dispatch(sendResetData(resetData))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth))