/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button'
import AddressForm from '../AddressForm/AddressForm'
import './Address.scss'

class Address extends Component {
	state = {
		address: '',
		city: '',
		street: '',
		house: '',
		flat: '',
		viewForm: false
	}
	
	onChange(){return null}
	
	cancelAddAddresForm = () => {
		this.setState({viewForm: false})
	}
	
	addAddresForm = () => {
		const address = this.props.address
		const city = address.split(',', 4)[0]?address.split(',', 4)[0].trim():''
		const street = address.split(',', 4)[1]?address.split(',', 4)[1].trim():''
		const house = address.split(',', 4)[2]?address.split(',', 4)[2].trim():''
		let flat = ''
		if(address.lenght > 3){
			flat = this.props.address.split(',', 4)[3]?this.props.address.split(',', 4)[3].trim():''
		}
		
		this.setState({
			address: city,
			city: street,
			street: house,
			house: house,
			flat: flat,
			viewForm: true
		})
	}
	
	addressRender(){
		return (
			<div id={'address_wrap_' + this.props.address_id} className={'address_wrap'}>
				<div id={'address_' + this.props.address_id} className={'address'}>
					<Input
						type={'text'}
						data-address_id={this.props.address_id}
						name={'address_' + this.props.address_id}
						value={this.props.address}
						id={'input-address-' + this.props.address_id}
						className={'input-address'}
						onFocus={this.addAddresForm}
						onChange={this.onChange}
					/>
				</div>

				{this.props.default
				?	<div className="button-group">
						<span
							className="text-default" 
							id={'default' + this.props.address_id}
						>Основной
						</span>
					</div>
				:	<div className="button-group">
						<span
							className="text-do-default"
							id={'default' + this.props.address_id}
							onClick ={this.props.setAddressDefault}
						>Сделать основным
						</span>
						<Button 
							label="Удалить"
							classes={['delete_addres', 'delete_button']}
							onClick ={this.props.deleteAddress}
						/>
					</div>
				}
			</div>
		)
	}
	
	
	render(){
		return(
			<div className="wrap">
				{ this.state.viewForm
					? <AddressForm
						address_id={this.props.address_id}
						address={this.props.address}
						cancelAddAddresForm={this.cancelAddAddresForm}
						
					  />
					: this.addressRender()
				}
			</div>	
		)
	}
}

export default Address;