/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/

import axios from 'axios'
import {
	FETCH_MAIN_INFO_START,
	FETCH_MAIN_INFO_SUCCESS,
	FETCH_MAIN_INFO_ERROR,
	SEND_MAIN_INFO_START, 
	SEND_MAIN_INFO_SUCCESS, 
	SEND_MAIN_INFO_ERROR, 
	GET_MAIN_INFO
} from './actionTypes'



export function fetchMainInfo(callback = () => {}){
	return async dispatch => {
		dispatch(fetchMainInfoStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.get(testUrl + '/index.php?route=account/account/maininfo&app_test=1')
			console.log(responce)
			const info = {
				formControls: {
					firstname:{
						value: responce.data.firstname,
						type: 'text',
						label: responce.data.entry_firstname?responce.data.entry_firstname:'Имя',
						placeholder: responce.data.entry_firstname?responce.data.entry_firstname:'Имя',
						errorMessage: responce.data.error_warning?responce.data.error_firstname:'',
						valid: !responce.data.error_firstnamee || !responce.data.error_warning,
						touched: false,
						validation:{
							required: true,
							minLength: 2,
							maxLength: 30
						}
					},
					lastname:{
						value: responce.data.lastname,
						type: 'text',
						label: responce.data.entry_lastname?responce.data.entry_lastname:'Фамилия',
						placeholder: responce.data.entry_lastname?responce.data.entry_lastname:'Фамилия',
						errorMessage: responce.data.error_warning?responce.data.error_lastname:'',
						valid: !responce.data.error_lastname || !responce.data.error_warning,
						touched: false,
						validation:{
							required: true,
							minLength: 2,
							maxLength: 30
						}
					},
					email:{
						value: responce.data.email,
						type: 'email',
						label: responce.data.entry_email?responce.data.entry_email:'E-mail',
						placeholder: responce.data.entry_email?responce.data.entry_email:'E-mail',
						errorMessage: responce.data.error_warning?responce.data.error_email:'',
						valid: !responce.data.error_email || !responce.data.error_warning,
						touched: false,
						validation:{
							required: true,
							email: true
						}
					},
					telephone:{
						value: responce.data.telephone,
						type: 'tel',
						label: responce.data.entry_telephone?responce.data.entry_telephone:'Телефон',
						placeholder: responce.data.entry_telephone?responce.data.entry_telephone:'Телефон',
						errorMessage: responce.data.error_warning?responce.data.error_telephone:'',
						valid: !responce.data.error_telephone || !responce.data.error_warning,
						touched: false,
						mask: "+37\\5 99 999 99 99",
						validation:{
							required: true,
							tel: true
						}
					}
				},
				
				needupdate: false
			}

			if(responce.data.carrot_auth && responce.data.carrot_identify){
				eval(responce.data.carrot_auth)
				eval(responce.data.carrot_identify)
				// console.log('eval carrot_auth & carrot_identify')
				// console.log(responce.data.carrot_auth)
				// console.log(responce.data.carrot_identify)
			}

			dispatch(fetchMainInfoSuccess(info))
			callback()
		} catch(e){
			console.log(e)
			dispatch(fetchMainInfoError(e))
		}
	}
}

export function sendMainInfoData(mainInfoData, callback = () => {}){
	return async dispatch => {
		dispatch(sendMainInfoStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.post(testUrl + '/index.php?route=account/account/maininfo&app_test=1', mainInfoData)
			if(responce.status === 200){
				const responce = await axios.get(testUrl + '/index.php?route=account/account/maininfo&app_test=1')
				const info = {
					formControls: {
						firstname:{
							value: responce.data.firstname,
							type: 'text',
							label: responce.data.entry_firstname?responce.data.entry_firstname:'Имя',
							placeholder: responce.data.entry_firstname?responce.data.entry_firstname:'Имя',
							errorMessage: responce.data.error_warning?responce.data.error_firstname:'',
							valid: !responce.data.error_firstnamee,
							touched: false,
							validation:{
								required: true,
								minLength: 2,
								maxLength: 30
							}
						},
						lastname:{
							value: responce.data.lastname,
							type: 'text',
							label: responce.data.entry_lastname?responce.data.entry_lastname:'Фамилия',
							placeholder: responce.data.entry_lastname?responce.data.entry_lastname:'Фамилия',
							errorMessage: responce.data.error_warning?responce.data.error_lastname:'',
							valid: !responce.data.error_lastname,
							touched: false,
							validation:{
								required: true,
								minLength: 2,
								maxLength: 30
							}
						},
						email:{
							value: responce.data.email,
							type: 'email',
							label: responce.data.entry_email?responce.data.entry_email:'E-mail',
							placeholder: responce.data.entry_email?responce.data.entry_email:'E-mail',
							errorMessage: responce.data.error_warning?responce.data.error_email:'',
							valid: !responce.data.error_email,
							touched: false,
							validation:{
								required: true,
								email: true
							}
						},
						telephone:{
							value: responce.data.telephone,
							type: 'tel',
							label: responce.data.entry_telephone?responce.data.entry_telephone:'Телефон',
							placeholder: responce.data.entry_telephone?responce.data.entry_telephone:'Телефон',
							errorMessage: responce.data.error_warning?responce.data.error_telephone:'',
							valid: !responce.data.error_telephone,
							touched: false,
							mask: "+37\\5 99 999 99 99",
							validation:{
								required: true,
								tel: true
							}
						}
					},

					needupdate: false
				}
				dispatch(fetchMainInfoSuccess(info))
				console.log(callback)
				if(responce.data.carrot_auth && responce.data.carrot_identify){
					eval(responce.data.carrot_auth)
					eval(responce.data.carrot_identify)
					console.log('eval carrot_auth & carrot_identify')
					console.log(responce.data.carrot_auth)
					console.log(responce.data.carrot_identify)
				}
				callback()
			} else {
				dispatch(sendMainInfoError({error: "Ошибка связи с сервером"}))
			}
		} catch(e){
			console.log(e)
			dispatch(sendMainInfoError(e))
		}
	}
}


export function fetchMainInfoStart(){
	return {
		type: FETCH_MAIN_INFO_START
	}
}
export function fetchMainInfoSuccess(info){
	return {
		type: FETCH_MAIN_INFO_SUCCESS,
		formControls: info.formControls,
		needupdate: info.needupdate
	}
}
export function fetchMainInfoError(e){
	return {
		type: FETCH_MAIN_INFO_ERROR,
		error: e
	}
}
export function sendMainInfoStart(){
	return {
		type: SEND_MAIN_INFO_START
	}
}
export function sendMainInfoSuccess(info){
	return {
		type: SEND_MAIN_INFO_SUCCESS,
		editResponce: info
	}
}
export function sendMainInfoError(e){
	return {
		type: SEND_MAIN_INFO_ERROR,
		error: e
	}
}
export function getMainInfo(){
	return {
		type: GET_MAIN_INFO
	}
}


