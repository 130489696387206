/*
    Created on : 15.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import './Footer.scss'
import {connect} from 'react-redux'
//import {fetchMainInfo, getMainInfo, sendMainInfoData} from '../../store/actions/mainInfo'

class Footer extends Component {

    state = {
        formControls: {}
    }

    render(){
        return(
            <div className="footer">
                <div className="wrapper dflex">
                    <div className="foot_item foot_logo">
                        <a href="/" className="foot_logo_link">
                            <img src={require('../../image/images/ecobar_352x124_onliner_.png')} alt=""/>
                            {/*<img src={require('../../image/images/logo_ecob_ny_2020.svg')} alt="" />*/}
                        </a>
                    </div>
                    <div className="foot_item foot_item_worktime">
                        <div className="foot_title">Режим работы</div>
                        <div className="foot_content"><strong>Пн-Пт c 9:00 до 18:00 по будням</strong></div>
                    </div>
                    <div className="foot_item foot_item_address">
                        <div className="foot_title">Наш адрес</div>
                        <div className="foot_content"><strong>пер. Домашевский 11А - 716, Минск</strong></div>
                    </div>
                    <div className="foot_item foot_item_phone">
                        <div className="foot_title">МТС, A1</div>
                        <div className="foot_content"><strong>731-90-90</strong></div>
                    </div>
                    <div className="foot_item foot_payments">
                        <img src={require('../../image/images/cards_to_pay.jpg')} alt="" />
                    </div>
                </div>
                <div className="foot_copy">
                    <div className="wrapper">ООО «Экобар Грушевка», юр.адес: 220069, г. Минск, пр-т Дзержинского, д.19, пом.884. УНП: 193533583 ОКПО: 504979165000. Свидетельство о государственной регистрации выдано Минским горисполкомом от 13.04.2021 с регистрационным номером 193533583. В торговом реестре с 31 августа 2022г.
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        // formControls: state.mainInfo.formControls,
        // loading: state.mainInfo.loading,
        // error: state.mainInfo.error,
        // needupdate: state.mainInfo.needupdate
    }
}

function mapDispatchToProps(dispatch){
    return {
        // fetchMainInfo: callback => dispatch(fetchMainInfo(callback)),
        // getMainInfo: () => dispatch(getMainInfo()),
        // sendMainInfoData: mainInfoData => dispatch(sendMainInfoData(mainInfoData))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer)