/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/

import axios from 'axios'
import {
	FETCH_ORDERS_START, 
	FETCH_ORDERS_SUCCESS, 
	FETCH_ORDERS_ERROR, 
	GET_ORDERS
} from './actionTypes'

export function fetchOrders(page = 1){
	return async dispatch => {
		dispatch(fetchOrdersStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}
		
		try{
			const responce = await axios.get(testUrl + '/index.php?route=account/account/orders&app_test=1&page=' + page)
			console.log(responce)
			if(responce){
				const info = {
					ordersInfo:{
						heading_title: responce.data.heading_title,
						text_empty: responce.data.text_empty,
						column_order_id: responce.data.column_order_id,
						column_customer: responce.data.column_customer,
						column_product: responce.data.column_product,
						column_total: responce.data.column_total,
						column_status: responce.data.column_status,
						column_date_added: responce.data.column_date_added,
						button_view: responce.data.column_date_added,
						orders: responce.data.orders,
						total_orders: responce.data.total_orders,
						results: responce.data.results,
					},
					needupdate: false
				}
				dispatch(fetchOrdersSuccess(info))
			}
		} catch(e){
			console.log(e)
			dispatch(fetchOrdersError(e))
		}
	}
}

export function fetchOrdersStart(){
	return {
		type: FETCH_ORDERS_START
	}
}
export function fetchOrdersSuccess(info){
	return {
		type: FETCH_ORDERS_SUCCESS,
		ordersInfo: info.ordersInfo,
		needupdate: info.needupdate
	}
}
export function fetchOrdersError(e){
	return {
		type: FETCH_ORDERS_ERROR,
		error: e
	}
}

export function getOrders(){
	return {
		type: GET_ORDERS
	}
}


