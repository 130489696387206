/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
//import Preloader from '../Preloader/Preloader'
import Pagination from '../Pagination/Pagination'
import './OrderHistory.scss'
import {connect} from 'react-redux'
import {fetchOrders, getOrders} from '../../store/actions/orders'
import {withRouter} from 'react-router-dom'

class OrderHistory extends Component {
	
	componentDidMount(){
		if(this.props.needupdate){
			this.props.fetchOrders(this.props.match.params.page)
		} else {
			this.props.getOrders()
		}
	}
	
	paymentSvg1 = () => { 
		return (
			<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)"><path fillRule="evenodd" clipRule="evenodd" d="M1.875 3.75L0 5.625L5.625 11.25L15 1.875L13.125 0L5.625 7.5L1.875 3.75Z" fill="#4F9E34"/></g><defs><clipPath id="clip0"><rect width="15" height="11.25" fill="white"/></clipPath></defs></svg>
		)
	}
	paymentSvg2 = () => { 
		return (
			<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.73808 0.476302L9.73804 0.476259L9.73416 0.480101L5.50117 4.6695L1.26615 0.48006L1.26619 0.480018L1.26227 0.476302C1.05594 0.280828 0.728645 0.280828 0.522312 0.476302C0.30911 0.678283 0.308321 1.00933 0.519943 1.21226L4.80904 5.46418L0.519975 9.71418C0.308321 9.91711 0.309099 10.2482 0.522312 10.4502C0.623775 10.5463 0.758326 10.5973 0.891255 10.5973C1.01997 10.5973 1.15789 10.5509 1.26359 10.4469L1.26408 10.4464L5.50121 6.25496L9.73835 10.4464L9.73884 10.4469C9.84453 10.5509 9.98246 10.5973 10.1112 10.5973C10.2441 10.5973 10.3787 10.5463 10.4801 10.4502C10.6933 10.2482 10.6941 9.91712 10.4825 9.71419L6.1943 5.4632L10.4804 1.21224C10.692 1.0093 10.6912 0.678275 10.478 0.476302C10.2717 0.280828 9.94442 0.280828 9.73808 0.476302Z" fill="#C61D41" stroke="#C61D41" stroke-width="0.5"/></svg>
		)
	
	}
	
	paymentSvg3 = () => { 
		return (
			<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)"><path fillRule="evenodd" clipRule="evenodd" d="M1.875 3.75L0 5.625L5.625 11.25L15 1.875L13.125 0L5.625 7.5L1.875 3.75Z" fill="#4F9E34"/></g><defs><clipPath id="clip0"><rect width="15" height="11.25" fill="white"/></clipPath></defs></svg>
		)
	}
	
	paymentSvg4 = () => { 
		return (
			<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.73808 0.476302L9.73804 0.476259L9.73416 0.480101L5.50117 4.6695L1.26615 0.48006L1.26619 0.480018L1.26227 0.476302C1.05594 0.280828 0.728645 0.280828 0.522312 0.476302C0.30911 0.678283 0.308321 1.00933 0.519943 1.21226L4.80904 5.46418L0.519975 9.71418C0.308321 9.91711 0.309099 10.2482 0.522312 10.4502C0.623775 10.5463 0.758326 10.5973 0.891255 10.5973C1.01997 10.5973 1.15789 10.5509 1.26359 10.4469L1.26408 10.4464L5.50121 6.25496L9.73835 10.4464L9.73884 10.4469C9.84453 10.5509 9.98246 10.5973 10.1112 10.5973C10.2441 10.5973 10.3787 10.5463 10.4801 10.4502C10.6933 10.2482 10.6941 9.91712 10.4825 9.71419L6.1943 5.4632L10.4804 1.21224C10.692 1.0093 10.6912 0.678275 10.478 0.476302C10.2717 0.280828 9.94442 0.280828 9.73808 0.476302Z" fill="#C61D41" stroke="#C61D41" strokeWidth="0.5"/></svg>
		)
	}
	
	toOrder(orderId){
		this.props.history.push('/order/' + orderId) 
	}
	
	renderOrdersListTable = () => {
		const data = this.props.ordersInfo
		//console.log('data', data)
		if(data && data.orders){
			return	Object.keys(data.orders).map((index) => { 
				let color = "text"
				let payment
				const order = data.orders[index]
				if(order.payment_inner_status === '3' || order.payment_inner_status === '4'){
					 payment = this.paymentSvg1()
				} else if(order.payment_inner_status === '1' || order.payment_inner_status === '5'){
					payment = this.paymentSvg2()
				}
				if(order.status.indexOf('Выполн') !== -1){
					color = 'green'
					if(!order.payment_inner_status){
						payment = this.paymentSvg3()
					}
				} else if(order.status.indexOf('Отмен') !== -1){
					color = 'red' 
					payment = this.paymentSvg4()
				}

				
				return (
					<div key={'order-tr-' + index} className="tr">
						<div className="table-cell order-cell">
							<span className="order-info-title mobile-block">{ data.column_order_id }</span>
							<span className="order_id order_info" 
								onClick = { this.toOrder.bind(this, order.order_id) }
							>{ order.order_id }</span>

							{order.shipping_code === 'express.express'
								? <span className='order-icon' data-info='Экспресс'>
									<span className="order-icon-info">Экспресс</span>
									<svg style={{maxHeight: '17px'}} width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3324 1.97771C9.56833 1.10564 10.3596 0.5 11.263 0.5H19.9498C21.2214 0.5 22.1701 1.67098 21.9063 2.91486L19.305 15.1829C19.1094 16.1055 18.2958 16.766 17.3527 16.768L14.5978 16.7737C14.6126 16.6507 14.6203 16.5254 14.6203 16.3984C14.6203 14.6854 13.2316 13.2968 11.5187 13.2968C9.80568 13.2968 8.41704 14.6854 8.41704 16.3984C8.41704 16.5298 8.42522 16.6594 8.44109 16.7865L6.24091 16.7911C5.77934 16.792 5.44321 16.3538 5.56375 15.9083L9.3324 1.97771Z" fill="#FABC22"/>
                                        <path d="M8.82143 16.7857H6.10714C5.73257 16.7857 5.42857 16.4817 5.42857 16.1071C5.42857 15.7326 5.73257 15.4286 6.10714 15.4286H8.82143C9.196 15.4286 9.5 15.7326 9.5 16.1071C9.5 16.4817 9.196 16.7857 8.82143 16.7857Z" fill="#343434"/>
                                        <path d="M30.875 16.7857H29.1786C28.804 16.7857 28.5 16.4817 28.5 16.1071C28.5 15.7326 28.804 15.4286 29.1786 15.4286H30.3118L31.2265 10.5537C31.2143 8.05929 29.0836 5.92857 26.4643 5.92857H22.0088L19.8496 15.4286H23.75C24.1246 15.4286 24.4286 15.7326 24.4286 16.1071C24.4286 16.4817 24.1246 16.7857 23.75 16.7857H19C18.7937 16.7857 18.5983 16.6921 18.4694 16.5306C18.3404 16.3704 18.2916 16.1587 18.3377 15.9579L20.805 5.10071C20.8756 4.78993 21.1497 4.57143 21.4673 4.57143H26.4643C29.8314 4.57143 32.5714 7.3115 32.5714 10.6786L31.5414 16.232C31.4816 16.5536 31.2021 16.7857 30.875 16.7857Z" fill="#343434"/>
                                        <path d="M26.4643 19.5C24.5941 19.5 23.0714 17.9786 23.0714 16.1071C23.0714 14.2356 24.5941 12.7143 26.4643 12.7143C28.3344 12.7143 29.8571 14.2356 29.8571 16.1071C29.8571 17.9786 28.3344 19.5 26.4643 19.5ZM26.4643 14.0714C25.3419 14.0714 24.4286 14.9848 24.4286 16.1071C24.4286 17.2295 25.3419 18.1429 26.4643 18.1429C27.5866 18.1429 28.5 17.2295 28.5 16.1071C28.5 14.9848 27.5866 14.0714 26.4643 14.0714Z" fill="#343434"/>
                                        <path d="M11.5357 19.5C9.66557 19.5 8.14286 17.9786 8.14286 16.1071C8.14286 14.2356 9.66557 12.7143 11.5357 12.7143C13.4059 12.7143 14.9286 14.2356 14.9286 16.1071C14.9286 17.9786 13.4059 19.5 11.5357 19.5ZM11.5357 14.0714C10.4134 14.0714 9.5 14.9848 9.5 16.1071C9.5 17.2295 10.4134 18.1429 11.5357 18.1429C12.6581 18.1429 13.5714 17.2295 13.5714 16.1071C13.5714 14.9848 12.6581 14.0714 11.5357 14.0714Z" fill="#343434"/>
                                        <path d="M8.82143 4.57143H3.39286C3.01829 4.57143 2.71429 4.26743 2.71429 3.89286C2.71429 3.51829 3.01829 3.21429 3.39286 3.21429H8.82143C9.196 3.21429 9.5 3.51829 9.5 3.89286C9.5 4.26743 9.196 4.57143 8.82143 4.57143Z" fill="#343434"/>
                                        <path d="M8.82143 8.64286H2.03571C1.66114 8.64286 1.35714 8.33886 1.35714 7.96429C1.35714 7.58971 1.66114 7.28571 2.03571 7.28571H8.82143C9.196 7.28571 9.5 7.58971 9.5 7.96429C9.5 8.33886 9.196 8.64286 8.82143 8.64286Z" fill="#343434"/>
                                        <path d="M8.82143 12.7143H0.678571C0.304 12.7143 0 12.4103 0 12.0357C0 11.6611 0.304 11.3571 0.678571 11.3571H8.82143C9.196 11.3571 9.5 11.6611 9.5 12.0357C9.5 12.4103 9.196 12.7143 8.82143 12.7143Z" fill="#343434"/>
                                        <path d="M19 16.7857H14.25C13.8754 16.7857 13.5714 16.4817 13.5714 16.1071C13.5714 15.7326 13.8754 15.4286 14.25 15.4286H18.4585L21.5419 1.85714H6.10714C5.73257 1.85714 5.42857 1.55314 5.42857 1.17857C5.42857 0.804 5.73257 0.5 6.10714 0.5H22.3929C22.5991 0.5 22.7946 0.593643 22.9235 0.755143C23.0524 0.915286 23.1013 1.127 23.0551 1.32786L19.6623 16.2564C19.5917 16.5672 19.3162 16.7857 19 16.7857Z" fill="#343434"/>
                                    </svg>
								</span>
								: null
							}
							{ order.order_method === 'grushevka'
								? <span className="order-icon">
										<span className="order-icon-info">Грушевка</span>
										<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
											<g>
												<path d="M488.468,448.903h-8.532V226.4C499.202,214.003,512,192.386,512,167.822c0-1.305-0.341-2.588-0.988-3.721L451.499,59.953    c-4.18-7.313-12.009-11.857-20.432-11.857H80.933c-8.423,0-16.252,4.543-20.432,11.857L0.988,164.101    C0.341,165.234,0,166.518,0,167.822c0,24.564,12.798,46.181,32.064,58.578v222.503h-8.532c-4.142,0-7.5,3.358-7.5,7.5    c0,4.142,3.358,7.5,7.5,7.5h464.936c4.143,0,7.5-3.358,7.5-7.5C495.968,452.261,492.61,448.903,488.468,448.903z M15.517,175.322    h24.044c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H20.424l53.101-92.927c1.516-2.652,4.354-4.299,7.408-4.299h350.134    c3.054,0,5.893,1.647,7.408,4.299l53.1,92.927h-19.141c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h24.048    c-3.667,26.584-26.532,47.125-54.108,47.125c-27.575,0-50.429-20.543-54.097-47.125h52.096c4.143,0,7.5-3.358,7.5-7.5    c0-4.142-3.357-7.5-7.5-7.5H71.631c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h52.091    c-3.668,26.582-26.523,47.125-54.097,47.125C42.049,222.447,19.184,201.906,15.517,175.322z M372.222,175.322    c-3.668,26.582-26.523,47.125-54.097,47.125c-27.575,0-50.429-20.543-54.097-47.125H372.222z M247.972,175.322    c-3.668,26.582-26.523,47.125-54.097,47.125c-27.574,0-50.429-20.543-54.097-47.125H247.972z M424.854,448.904h-81.193v-25.081    h81.193V448.904z M424.854,408.823h-81.193V271.516h81.193V408.823z M464.936,448.904h-25.081V264.016c0-4.142-3.357-7.5-7.5-7.5    h-96.193c-4.143,0-7.5,3.358-7.5,7.5v184.887H47.064V233.674c7.081,2.433,14.665,3.773,22.561,3.773    c27.095,0,50.624-15.556,62.125-38.207c11.501,22.65,35.03,38.207,62.125,38.207c27.095,0,50.624-15.556,62.125-38.207    c11.501,22.65,35.03,38.207,62.125,38.207c27.095,0,50.624-15.556,62.125-38.207c11.501,22.65,35.03,38.207,62.125,38.207    c7.896,0,15.48-1.34,22.561-3.772V448.904z"/>
											</g>
											<g>
												<path d="M296.081,256.516H79.645c-4.142,0-7.5,3.358-7.5,7.5v152.307c0,4.142,3.358,7.5,7.5,7.5h216.436    c4.143,0,7.5-3.358,7.5-7.5V264.016C303.581,259.873,300.224,256.516,296.081,256.516z M288.581,408.823H87.145V271.516h201.436    V408.823z"/>
											</g>
										</svg>
									</span>
								: null
							}
							{ order.order_method === 'borovaya'
								? <span className="order-icon">
										<span className="order-icon-info">Боровая</span>
										<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
											<g>
												<path d="M488.468,448.903h-8.532V226.4C499.202,214.003,512,192.386,512,167.822c0-1.305-0.341-2.588-0.988-3.721L451.499,59.953    c-4.18-7.313-12.009-11.857-20.432-11.857H80.933c-8.423,0-16.252,4.543-20.432,11.857L0.988,164.101    C0.341,165.234,0,166.518,0,167.822c0,24.564,12.798,46.181,32.064,58.578v222.503h-8.532c-4.142,0-7.5,3.358-7.5,7.5    c0,4.142,3.358,7.5,7.5,7.5h464.936c4.143,0,7.5-3.358,7.5-7.5C495.968,452.261,492.61,448.903,488.468,448.903z M15.517,175.322    h24.044c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H20.424l53.101-92.927c1.516-2.652,4.354-4.299,7.408-4.299h350.134    c3.054,0,5.893,1.647,7.408,4.299l53.1,92.927h-19.141c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h24.048    c-3.667,26.584-26.532,47.125-54.108,47.125c-27.575,0-50.429-20.543-54.097-47.125h52.096c4.143,0,7.5-3.358,7.5-7.5    c0-4.142-3.357-7.5-7.5-7.5H71.631c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h52.091    c-3.668,26.582-26.523,47.125-54.097,47.125C42.049,222.447,19.184,201.906,15.517,175.322z M372.222,175.322    c-3.668,26.582-26.523,47.125-54.097,47.125c-27.575,0-50.429-20.543-54.097-47.125H372.222z M247.972,175.322    c-3.668,26.582-26.523,47.125-54.097,47.125c-27.574,0-50.429-20.543-54.097-47.125H247.972z M424.854,448.904h-81.193v-25.081    h81.193V448.904z M424.854,408.823h-81.193V271.516h81.193V408.823z M464.936,448.904h-25.081V264.016c0-4.142-3.357-7.5-7.5-7.5    h-96.193c-4.143,0-7.5,3.358-7.5,7.5v184.887H47.064V233.674c7.081,2.433,14.665,3.773,22.561,3.773    c27.095,0,50.624-15.556,62.125-38.207c11.501,22.65,35.03,38.207,62.125,38.207c27.095,0,50.624-15.556,62.125-38.207    c11.501,22.65,35.03,38.207,62.125,38.207c27.095,0,50.624-15.556,62.125-38.207c11.501,22.65,35.03,38.207,62.125,38.207    c7.896,0,15.48-1.34,22.561-3.772V448.904z"/>
											</g>
											<g>
												<path d="M296.081,256.516H79.645c-4.142,0-7.5,3.358-7.5,7.5v152.307c0,4.142,3.358,7.5,7.5,7.5h216.436    c4.143,0,7.5-3.358,7.5-7.5V264.016C303.581,259.873,300.224,256.516,296.081,256.516z M288.581,408.823H87.145V271.516h201.436    V408.823z"/>
											</g>
										</svg>
									</span>
								: null
							}
						</div>
						<div className="table-cell card-cell">
						<span className="order-info-title mobile-block"></span>
						</div>
						<div className="table-cell product-cell">
							<span className="order-info-title mobile-block">{ data.column_product }</span><span className="product">{ order.products }</span>
						</div>
						<div className="table-cell status-cell">
							<span className="order-info-title mobile-block">{ data.column_status }</span><span className={"status " + color }>{ order.status }</span>
						</div>
						<div className="table-cell payment-cell">
							<span className="order-info-title mobile-block">Оплата</span><span className="payment">{ payment }</span>
						</div>
						<div className="table-cell total-cell">
							<span className="order-info-title mobile-block">{ data.column_total }</span><span className="total">{ order.total.replace(/<!--[\s\S]*?--!?>/g, "").replace(/<\/?[a-z][^>]*(>|$)/gi, "") }</span>
						</div>
						<div className="table-cell date-cell">
							<span className="order-info-title mobile-block">{ data.column_date_added }</span><span className="date_added">{ order.date_added }</span>
						</div>
					</div>
				)
			})
		}
	}
	
	changePage(page){
		this.props.fetchOrders(page)
	}
	
	renderOrdersList(){
		const data = this.props.ordersInfo
		if (data && data.orders){
			return (
				<div>
					<div id="order-table-wrap" className="order-wrap">
						<div className="order-title-wrap">
							<h2>{data.heading_title}</h2>
						</div>
						<div id="order-table">
							<div className="order-table">
								<div className="theader desctop-block">
									<div className="th">
										<div className="table-cell order-cell">
											<span className="order_id">{ data.column_order_id }</span>
										</div>
										<div className="table-cell card-cell">
										</div>
										<div className="table-cell product-cell">
											<span className="product">{ data.column_product }</span>
										</div>
										<div className="table-cell status-cell">
											<span className="status">{ data.column_status }</span>
										</div>
										<div className="table-cell payment-cell">
											<span className="payment">Оплата</span>
										</div>
										<div className="table-cell total-cell">
											<span className="total">{ data.column_total }</span>
										</div>
										<div className="table-cell date-cell">
											<span className="date_added">{ data.column_date_added }</span>
										</div>
									</div>
								</div>
								<div className="tbody">
									{ this.renderOrdersListTable() }
								</div>				
							</div>
						</div>
					</div>
					<Pagination 
						total={data.total_orders}
						currentPage={this.props.page}
						limit={10}
						changePage={this.changePage.bind(this)}
						base="/orders"
					/>
				</div>
			)
		} else {
			
			return (
				<p>{ data.text_empty }</p>
			)
		}
	}
	
	render(){
		return(
			<div>		
				{ !this.props.ordersInfo.needupdate
					? this.renderOrdersList()
					: null
				}
			</div>
		)
	}
}

function mapStateToProps(state){
	return {
		ordersInfo: state.orders.ordersInfo,
		loading: state.orders.loading,
		error: state.orders.error,
		needupdate: state.orders.needupdate
	}
}

function mapDispatchToProps(dispatch){
	return {
		fetchOrders: page => dispatch(fetchOrders(page)),
		getOrders: () => dispatch(getOrders())
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderHistory))