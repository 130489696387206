/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/
import {FETCH_MAIN_INFO_START, FETCH_MAIN_INFO_SUCCESS, FETCH_MAIN_INFO_ERROR, SEND_MAIN_INFO_START, SEND_MAIN_INFO_SUCCESS, SEND_MAIN_INFO_ERROR,GET_MAIN_INFO} from '../actions/actionTypes'

let initalState = {
	
	formControls:{
		firstname:{
			value: '',
			type: 'text',
			label: 'Имя',
			placeholder: 'Имя',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{
				required: true,
				minLength: 2,
				maxLength: 30
			}
		},
		lastname:{
			value: '',
			type: 'text',
			label: 'Фамилия',
			placeholder: 'Фамилия',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{
				required: true,
				minLength: 2,
				maxLength: 30
			}
		},
		email:{
			value: '',
			type: 'email',
			label: 'E-mail',
			placeholder: 'E-mail',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{
				required: true,
				email: true
			}
		},
		telephone: {
			value: '',
			type: 'tel',
			label: 'Телефон',
			placeholder: 'Телефон',
			errorMessage: '',
			valid: true,
			touched: false,
			mask: "+37\\5 99 999 99 99",
			validation: {
				required: true,
				tel: true
			}
		},
	},
	loading: false,
	error: null,
	needupdate: true
	
}

export default function mainInfoReducer(state = initalState, action){
	
	switch(action.type){
		case FETCH_MAIN_INFO_START:
			return{
				...state, loading: true
			}
		case FETCH_MAIN_INFO_SUCCESS:

			initalState = { 
				...state, formControls: action.formControls, needupdate: false
			}
			return{
				...state, loading: false, formControls: action.formControls, needupdate: false
			}
		case FETCH_MAIN_INFO_ERROR:
			return{
				...state, loading: false, error: action.error
			}
		case SEND_MAIN_INFO_START:
			return{
				...state, loading: true
			}
		case SEND_MAIN_INFO_SUCCESS:

			initalState = { 
				...state, formControls: action.formControls, needupdate: false
			}
			return{
				...state, loading: false, formControls: action.formControls, needupdate: false
			}
		case SEND_MAIN_INFO_ERROR:
			return{
				...state, loading: false, error: action.error
			}
		case GET_MAIN_INFO:
			return state
			
		default:
			return state
	}
	
}