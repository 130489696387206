/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './LeftMenu.scss'
import {connect} from 'react-redux'
import {fetchMainMenuCounts, getMainMenuCounts} from '../../../store/actions/mainMenu'

class LeftMenu extends Component {

	state = {
		navLinks:[
			{
				label: "Избранные товары",
				to: '/wishlist',
				counter: 0,
				exact: false
			},
			{
				label: "Бонусная программа",
					to: '/bonuses',
				counter: 0,
				exact: false
			},
			{
				label: "История покупок",
					to: '/orders',
				counter: 0,
				exact: false
			},
			{
				label: "Мой профиль",
					to: '/',
				counter: 0,
				exact: true
			},
			{
				label: "Изменить пароль",
					to: '/password',
				counter: 0,
				exact: true
			}
		],
		logged: false,
		needupdate: true
	}


	UNSAFE_componentWillReceiveProps(nextProps){
		const navLinks = { ...this.state.navLinks }
		navLinks[0].counter = nextProps.counts.wishProducts
		navLinks[2].counter = nextProps.counts.orders
		const needupdate = nextProps.needupdate
		this.setState({ navLinks, needupdate } )
	}

	componentDidMount(){
		if(this.state.needupdate){
			this.props.fetchMainMenuCounts()
		} else {
			this.props.getMainMenuCounts()
		}
	}

	renderLinks(){
		return Object.keys(this.state.navLinks).map((index) => {
			const link = this.state.navLinks[index]
			return (
				<li key={ index }>
					<NavLink
						to={link.to}
						exact={link.exact}
						className="menu_li"
					>					
						<span className="menu_item">{ link.label }</span>
						{link.counter ?
							<span id="order_quantity" className="li_quantity">{ link.counter }</span>
						: null}
					</NavLink>
				</li>	
			)
		})
	}
	
	render(){
		return (
			<div id="account-menu" className="account-menu">
				<nav>
					<ul className="list-unstyled">
						{ this.renderLinks() }
						<li>
							<a href={"/logout"} className="menu_li">
								<span className="menu_item">Выход</span>
							</a>
						</li>
					</ul>
				</nav>
			</div>
		)
	}
}

function mapStateToProps(state){
	return {
		counts: state.mainMenu.counts,
		loading: state.mainMenu.loading,
		error: state.mainMenu.error,
		needupdate: state.mainMenu.needupdate
	}
}

function mapDispatchToProps(dispatch){
	return {
		fetchMainMenuCounts: callback => dispatch(fetchMainMenuCounts(callback)),
		getMainMenuCounts: () => dispatch(getMainMenuCounts())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu)





