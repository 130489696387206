/* 
    Created on : 05.02.2020, 14:52:46
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import './Pagination.scss'
import {withRouter} from 'react-router-dom'


class Pagination extends Component {
	
	paginationFirstLink(){
		const text_first = this.props.text_first?this.props.text_first:''
		const currentPage = (this.props.currentPage > 1)?this.props.currentPage:1
		
		if (currentPage > 1){
			return (
				<li
					onClick={() => {
							this.props.history.push(this.props.base + '/1')
							this.props.changePage(1)
						}
					}
				>
					<span className="pagi-first">{text_first}</span>
				</li>
			)
	
		} else {
			return null
		}
	}
	paginationPrevLink(){
		const text_prev = this.props.text_prev?this.props.text_prev:''
		const currentPage = (parseInt(this.props.currentPage) > 1)?parseInt(this.props.currentPage):1
		let targetPage = null

		if (currentPage > 1){
			if(currentPage - 1 === 1){
				targetPage = 1
			} else {
				targetPage = currentPage - 1
			}
			return (
				<li
					onClick={() => {
							this.props.history.push(this.props.base + '/' + targetPage)
							this.props.changePage(targetPage)
						}
					}
				>
					<span className="pagi-prev">{text_prev}</span>
				</li>
			)
	
		} else {
			return null
		}
	}
	
	paginationNextLink(){
		const text_next = this.props.text_next?this.props.text_next:''
		const total = parseInt(this.props.total)
		const currentPage = (parseInt(this.props.currentPage) > 1)?parseInt(this.props.currentPage):1
		const limit = parseInt(this.props.limit)?parseInt(this.props.limit):20
		const num_pages = Math.ceil(total/limit)

		if (currentPage < num_pages){
			const targetPage = currentPage + 1
			return (
				<li
					onClick={() => {
						this.props.history.push(this.props.base + '/' + targetPage)
						this.props.changePage(targetPage)
						}
					}
				>
					<span className="pagi-next">{text_next}</span>
				</li>
			)
	
		} else {
			return null
		}
	}
	
	paginationLastLink(){
		const text_last = this.props.text_last?this.props.text_last:''
		const total = parseInt(this.props.total)
		const currentPage = (parseInt(this.props.currentPage) > 1)?parseInt(this.props.currentPage):1
		const limit = parseInt(this.props.limit)?parseInt(this.props.limit):20
		const num_pages = Math.ceil(total/limit)

		if (currentPage < num_pages){
			return (
				<li
					onClick={() => {
							this.props.history.push(this.props.base + '/' + num_pages)
							this.props.changePage(num_pages)
						}
					}
				>
					<span className="pagi-last">{text_last}</span>
				</li>
			)
		} else {
			return null
		}
	}
	
	paginationLink(page){
		const currentPage = (parseInt(this.props.currentPage) > 1)?parseInt(this.props.currentPage):1
		let linkClass = null
		if (parseInt(currentPage) === page){
			linkClass = 'active'
			return (
				<li 
					key={'paginationLink' + page}
					className={linkClass}
					onClick={()=>this.props.history.push(this.props.base + '/' + page)}
				>
					<span>{page}</span>
				</li>
			)
		} else {
			return (
				<li
					key={'paginationLink' + page}
					className={linkClass}
					onClick={() => {
							this.props.history.push(this.props.base + '/' + page)
							this.props.changePage(page)
						}
					}
				>
					<span>{page}</span>
				</li>
			)
		}
	}

	paginationLinksRender = () => {
		const total = parseInt(this.props.total)
		const currentPage = (parseInt(this.props.currentPage) > 1)?parseInt(this.props.currentPage):1
		const limit = parseInt(this.props.limit)?parseInt(this.props.limit):20
		const num_links = parseInt(this.props.num_links)?parseInt(this.props.num_links):8
		const num_pages = Math.ceil(total/limit)
		let start = null
		let end = null
		let paginationLinks =[]

		if (num_pages > 1) {

			if (num_pages <= num_links) {
				start = 1
				end = num_pages
			} else {
				start = parseInt(currentPage) - Math.floor(num_links / 2);
				end = parseInt(currentPage) + Math.floor(num_links / 2);

				if (start < 1) {
					end += Math.abs(start) + 1;
					start = 1;
				}

				if (end > num_pages) {
					start -= (end - num_pages);
					end = num_pages;
				}
			}
			
			for (let i = start; i <= end; i++) {
				paginationLinks.push(i)
			}
			

			return paginationLinks.map((i) => {
				return this.paginationLink(i)
			})
		}
	}
	
	paginationRender = () => {
		const total = this.props.total
		const limit = this.props.limit?this.props.limit:20
		const num_pages = Math.ceil(total/limit)

		if(num_pages > 1){
			return (
				<div className="pagination-wrap">
					<div className="pagination">
						<ul className="pagination new_pagination_detect">
							{ this.paginationFirstLink() }
							{ this.paginationPrevLink() }
							{ this.paginationLinksRender() }
							{ this.paginationNextLink() }
							{ this.paginationLastLink() }
						</ul>
					</div>
				</div>
			)
		} else {
			return null
		}
	}

	render(){
		return this.paginationRender()
	}
}

export default withRouter(Pagination);
