/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Preloader from '../Preloader/Preloader'
import Address from '../Address/Address'
import './AddressList.scss'
import {connect} from 'react-redux'
import {
	fetchAddresses,
	getAddresses,
	deleteAddress,
	setAddressDefault
} from '../../store/actions/addresses'

class AddressList extends Component {
	
	refresh(){
		if(this.props.needupdate){
			this.props.fetchAddresses()
		} else {
			this.props.getAddresses()
		}
	}
	
	componentDidMount(){
		this.refresh()
	}
	
	setAddressDefaultHandler(address_id){
		this.props.setAddressDefault(address_id)
	}
	
	deleteAddressHandler(address_id){
		this.props.deleteAddress(address_id)
	}
	
	renderAddressList = () => {
		if(this.props.addresses && !this.props.addresses.needupdate){
			const addressList = Object.keys(this.props.addresses).map((index) => {
				const address = this.props.addresses[index]
				return(
					<Address
						key={'address_' + index}
						address={address.address}
						address_id={address.address_id}
						default={address.default}
						deleteAddress = {this.deleteAddressHandler.bind(this, address.address_id)}
						setAddressDefault = {this.setAddressDefaultHandler.bind(this, address.address_id)}
					/>
				)
			})

			return addressList
		} else {
			return null
		}
	}
	
	render(){
		return(
			<div id="addresslist">
				<h2>Адреса</h2>
				<div className="fieldset address_list">
				{this.renderAddressList()}
				{
					this.props.loading && this.props.length !== 0
					?	<Preloader />
					: null
				}
				</div>
			</div>
		)
	}
}


function mapStateToProps(state){
	return {
		addresses: state.addresses.addresses,
		loading: state.addresses.loading,
		error: state.addresses.error,
		needupdate: state.addresses.needupdate
	}
}

function mapDispatchToProps(dispatch){
	return {
		fetchAddresses: callback => dispatch(fetchAddresses(callback)),
		getAddresses: () => dispatch(getAddresses()),
		//sendNewAddressInfo: newAddressData => dispatch(sendNewAddressData(newAddressData))
		deleteAddress: address_id => dispatch(deleteAddress(address_id)),
		setAddressDefault: address_id => dispatch(setAddressDefault(address_id))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressList)