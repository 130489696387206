/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/

import axios from 'axios'
import {
	FETCH_ADDRESSES_START,
	FETCH_ADDRESSES_SUCCESS, 
	FETCH_ADDRESSES_ERROR, 
	SEND_NEW_ADDRESS_START,
	SEND_NEW_ADDRESS_ERROR, 
	SEND_ADDRESS_START,
	SEND_ADDRESS_ERROR, 
	GET_ADDRESSES,
	DELETE_ADDRESS_SUCCESS,
	SET_ADDRESS_DEFAULT_SUCCESS
} from '../actions/actionTypes'

export function fetchAddresses(){
	return async dispatch => {
		dispatch(fetchAddressesStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.get(testUrl + '/index.php?route=account/account/addressList&app_test=1')
			if(responce.status === 200){
				const info = {
					addresses: responce.data.addresses,		
					needupdate: false
				}

				dispatch(fetchAddressesSuccess(info))
			}
			
		} catch(e){
			console.log(e)
			dispatch(fetchAddressesError(e))
		}
	}
}

export function sendNewAddressData(newAddressData){
	return async dispatch => {
		dispatch(sendNewAddressStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.post(testUrl + '/index.php?route=account/account/addAddress&app_test=1', newAddressData)
			if(responce.status === 200) {

				dispatch(fetchAddresses())

				if (typeof responce.data.address_id !== 'undefined' && responce.data.address_id) {
					dispatch(setAddressDefault(responce.data.address_id))
				}

			} else {
				dispatch(sendNewAddressError({error: "Ошибка связи с сервером"}))
			}
		} catch(e){
			console.log(e)
			dispatch(sendNewAddressError(e))
		}
	}
}

export function sendAddressData(address_id, addressData){
	return async dispatch => {
		dispatch(sendAddressStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.post(testUrl + '/index.php?route=account/account/editAddress&app_test=1&address_id=' + address_id, addressData)
			
			if(responce.status === 200){
				
				dispatch(fetchAddresses())
				if (typeof responce.data.address_id !== 'undefined' && responce.data.address_id) {
					dispatch(setAddressDefault(responce.data.address_id))
				}
				
			} else {
				dispatch(sendAddressError({error: "Ошибка связи с сервером"}))
			}
		} catch(e){
			console.log(e)
			dispatch(sendAddressError(e))
		}
	}
}

export function setAddressDefault(address_id){
	return async dispatch => {

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.get(testUrl + '/index.php?route=account/account/updateDefaultAddress&app_test=1&address_id=' + address_id,)
			if(responce.status === 200){
				dispatch(setAddressDefaultSuccess())
				dispatch(fetchAddresses())
			}
		} catch(e){
			console.log(e)
			//dispatch(deleteAddressesError(e))
		}
	}
}

export function deleteAddress(address_id){
	return async dispatch => {

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.get(testUrl + '/index.php?route=account/account/deleteaddress&app_test=1&address_id=' + address_id,)
			if(responce.status === 200){
				dispatch(deleteAddressSuccess())
				dispatch(fetchAddresses())
			}
		} catch(e){
			console.log(e)
			//dispatch(deleteAddressesError(e))
		}
	}
}


export function fetchAddressesStart(){
	return {
		type: FETCH_ADDRESSES_START
	}
}
export function fetchAddressesSuccess(info){
	return {
		type: FETCH_ADDRESSES_SUCCESS,
		addresses: info.addresses,
		needupdate: info.needupdate
	}
}
export function fetchAddressesError(e){
	return {
		type: FETCH_ADDRESSES_ERROR,
		error: e
	}
}
export function sendNewAddressStart(){
	return {
		type: SEND_NEW_ADDRESS_START
	}
}
export function sendNewAddressError(e){
	return {
		type: SEND_NEW_ADDRESS_ERROR,
		error: e
	}
}
export function sendAddressStart(){
	return {
		type: SEND_ADDRESS_START
	}
}
export function sendAddressError(e){
	return {
		type: SEND_ADDRESS_ERROR,
		error: e
	}
}
export function getAddresses(){
	return {
		type: GET_ADDRESSES
	}
}

export function deleteAddressSuccess(){

	return {
		type: DELETE_ADDRESS_SUCCESS
	}
}

export function setAddressDefaultSuccess(){

	return {
		type: SET_ADDRESS_DEFAULT_SUCCESS
	}
}


