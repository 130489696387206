/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
//import Preloader from '../Preloader/Preloader'
import './OrderInfo.scss'
import axios from 'axios'
import Button from "../UI/Button/Button"
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchMainMenuCounts} from '../../store/actions/mainMenu'

class OrderInfo extends Component {
	
	state = {
		order: {}
	}
	
	async componentDidMount(){

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.get(testUrl + '/index.php?route=account/account/orderInfo&order_id=' + this.props.id)
			this.setState({ order: responce.data })
		} catch(e){
			console.log(e)
		}
	}

	async addProductToCart(order_product_id, order_id){

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			await axios.get(testUrl + '/index.php?route=account/account/reorder&order_product_id=' + order_product_id + '&order_id=' + order_id)
			this.props.fetchMainMenuCounts()
		} catch(e){
			console.log(e)
		}
	}

	renderProducts(){
		const map = {amp: '&', lt: '<', gt: '>', quot: '"', '#039': "'"}
		const data = this.state.order
		return Object.keys(data.products).map((index) => {
			const product = data.products[index]
			return (
				<div key={'product-' + index} className="tr">
					<div className="table-cell image-cell">
						{ product.thumb
							? <img src={ product.thumb } alt={ product.name } title={ product.name } className="img-thumbnail" />
							: null
						}
					</div>
					<div className="table-cell name-cell">
						<a href={ product.href.replace(/&([^;]+);/g, (m, c) => map[c]) }>{ product.name.replace(/&([^;]+);/g, (m, c) => map[c]) }</a>
					</div>
					<div className="table-cell model-cell">
						<span className="order-info-title mobile-block">{ data.column_model }</span><span>{ product.model }</span>
					</div>
					<div className="table-cell quantity-cell">
						<span className="order-info-title mobile-block">{ data.column_quantity }</span><span>{ product.quantity }</span>
					</div>
					<div className="table-cell price-cell">
						<span className="order-info-title mobile-block">{ data.column_price }</span><span className="price">{ product.price.replace(/<!--[\s\S]*?--!?>/g, "").replace(/<\/?[a-z][^>]*(>|$)/gi, "") }</span>
					</div>
					<div className="table-cell total-cell">
						<span className="order-info-title mobile-block">{ data.column_total }</span><span className="total">{ product.total.replace(/<!--[\s\S]*?--!?>/g, "").replace(/<\/?[a-z][^>]*(>|$)/gi, "") }</span>
					</div>
					<div className="table-cell cart-cell">
						{product.max_quantity > 0
							? <Button
								type="button"
								classes={["to-cart", "btn"]}
								onClick={() => {
									this.addProductToCart(product.order_product_id, data.order_id)
								}}
							>
								<span className="order-info-title mobile-block">В корзину</span>
								<svg className="desctop-block" width="19" height="18" viewBox="0 0 19 18" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0)">
										<path
											d="M7.50953 14.1564C6.45096 14.1564 5.58691 15.0211 5.58691 16.0805C5.58691 17.1398 6.45096 18.0045 7.50953 18.0045C8.5681 18.0045 9.42763 17.1398 9.43215 16.0805C9.43215 15.0166 8.5681 14.1564 7.50953 14.1564ZM8.52739 16.0759C8.52739 16.6373 8.07049 17.0946 7.50953 17.0946C6.94858 17.0946 6.49168 16.6373 6.49168 16.0759C6.49168 15.5146 6.94858 15.0573 7.50953 15.0573C8.07049 15.0619 8.52739 15.5146 8.52739 16.0759Z"
											fill="#4F9E34"/>
										<path
											d="M14.1234 14.1564C13.0649 14.1564 12.2008 15.0211 12.2008 16.0805C12.2008 17.1398 13.0649 18 14.1234 18.0045C14.6346 18.0045 15.1187 17.8053 15.4806 17.4432C15.8425 17.081 16.0415 16.5966 16.0415 16.085C16.0415 15.5734 15.8425 15.089 15.4806 14.7269C15.1187 14.3556 14.6346 14.1564 14.1234 14.1564ZM14.1234 15.0619C14.6844 15.0619 15.1413 15.5191 15.1413 16.0805C15.1413 16.3521 15.0372 16.6056 14.8427 16.8003C14.6527 16.9904 14.3949 17.0991 14.1234 17.0991C13.5625 17.0991 13.1056 16.6418 13.1056 16.0805C13.1056 15.5191 13.5625 15.0619 14.1234 15.0619Z"
											fill="#4F9E34"/>
										<path
											d="M18.914 2.9336C18.8281 2.8159 18.6924 2.74346 18.5476 2.74346H4.5781L4.41976 1.73843C4.26143 0.728873 3.41095 0 2.3931 0C2.38857 0 2.38857 0 2.38405 0H0.452381C0.203571 0 0 0.203722 0 0.452716C0 0.70171 0.203571 0.905433 0.452381 0.905433H2.38405H2.38857C2.95857 0.905433 3.4381 1.31288 3.52405 1.87877L5.0531 11.5443C5.2069 12.5538 6.0619 13.2827 7.07976 13.2827C7.08429 13.2827 7.08429 13.2827 7.08881 13.2827H16.539C16.7879 13.2827 16.9914 13.079 16.9914 12.83C16.9914 12.581 16.7879 12.3773 16.539 12.3773H7.08881H7.08429C6.52333 12.3773 6.03476 11.9608 5.94881 11.4039L5.71357 9.91901H15.2362C16.1138 9.91901 16.8964 9.36217 17.1814 8.5337L18.9774 3.34557C19.0226 3.20523 19 3.05584 18.914 2.9336ZM17.9098 3.64889L16.3219 8.23491C16.159 8.70121 15.7202 9.01358 15.2317 9.01358H5.56881L4.71833 3.64889H17.9098Z"
											fill="#4F9E34"/>
									</g>
									<defs>
										<clipPath id="clip0">
											<rect width="19" height="18" fill="white"/>
										</clipPath>
									</defs>
								</svg>
								<svg className="desctop-block" width="14" height="14" viewBox="0 0 14 14" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<rect x="6.5" width="1" height="14" fill="#4F9E34"/>
									<rect x="14" y="6.5" width="1" height="14" transform="rotate(90 14 6.5)"
										  fill="#4F9E34"/>
								</svg>
							  </Button>
							: null
						}
					</div>
				</div>
			)
		})

	}

	renderProductsTable(){
		const data = this.state.order
		if (data.products){
			return (
				<div>
					<div id="product-table">
						<div className="product-table">
							<div className="theader desctop-block">
								<div className="th">
									<div className="table-cell name-cell">
										<span>{ data.column_name }</span>
									</div>
									<div className="table-cell model-cell">
										<span>{ data.column_model }</span>
									</div>
									<div className="table-cell quantity-cell">
										<span>{ data.column_quantity }</span>
									</div>
									<div className="table-cell price-cell">
										<span>{ data.column_price }</span>
									</div>				
									<div className="table-cell total-cell">
										<span>{ data.column_total }</span>
									</div>				
								</div>
							</div>
							<div className="tbody">
								{ this.renderProducts() }	
							</div>
						</div>
					</div>
					<Button
						classes={["copy_order","btn"]}
						type="button"
						onClick={() => {this.addProductToCart(0, data.order_id)}}
					>
						Повторить заказ
					</Button>
				</div>
			)
		}
	}
	
	renderOrderInfoTable(){
		const data = this.state.order
		return(
			<div className="order-info-table">
				<div className="theader desctop-block">
					<div className="th">
						<div className="table-cell order-cell">
							<span className="order_id">{ data.text_order_id }</span>
						</div>
						<div className="table-cell date-cell">
							<span className="date_added">{ data.text_date_added }</span>
						</div>
						<div className="table-cell payment-method-cell">
							<span className="payment-method">{ data.text_payment_method }</span>
						</div>
						<div className="table-cell shipping-method-cell">
							<span className="shipping-method">{ data.text_shipping_method }</span>
						</div>				
					</div>
				</div>
				<div className="tbody">
					<div className="tr">
						<div className="table-cell order-cell">
							<span className="order-info-title mobile-block">{ data.text_order_id }</span><span className="order_id">{ data.order_id }</span>
						</div>
						<div className="table-cell date-cell">
							<span className="order-info-title mobile-block">{ data.text_date_added }</span><span className="date_added">{ data.date_added }</span>
						</div>
						<div className="table-cell payment-method-cell">
							<span className="order-info-title mobile-block">{ data.text_payment_method }</span><span className="payment-method">{ data.payment_method }</span>
						</div>
						<div className="table-cell shipping-method-cell">
							<span className="order-info-title mobile-block">{ data.text_shipping_method }</span><span className="shipping-method">{ data.shipping_method }</span>
						</div>		
					</div>
				</div>
			</div>
		)
	}
	
	renderOrderAddressInfo(){
		const data = this.state.order
		if (data.text_payment_address){
			return(
				<div className="address-info-wrap">
					<div className="address-info payment-address-info">
						<p className="address-title desctop-block">{ data.text_payment_address }</p>
						<span className="order-info-title mobile-block">{ data.text_payment_address }</span><p className="address-value">{ data.payment_address.split('<br />')[1] }</p>
					</div>
					<div className="address-info shipping-address-info">
						<p className="address-title desctop-block">{ data.text_shipping_address }</p>
						<span className="order-info-title mobile-block">{ data.text_shipping_address }</span><p className="address-value">{ data.shipping_address.split('<br />')[1] }</p>
					</div>
				</div>
			)
		}
	}

	render(){
		return(
			<div id="order-info-table-wrap" className="order-info-wrap">
				<div className="order-title-wrap">
					<h2>Детали заказа</h2>
					<span
						className="goback goback-history"
						onClick={() => {this.props.history.goBack()}}
					>Назад</span>
				</div>
				<div id="order-info-table">
					{ this.renderOrderInfoTable() }						
					{ this.renderOrderAddressInfo() }
				</div>
				{ this.renderProductsTable() }
			</div>
		)
	}
}

function mapDispatchToProps(dispatch){
	return {
		fetchMainMenuCounts: callback => dispatch(fetchMainMenuCounts(callback)),
	}
}

export default withRouter(connect(null, mapDispatchToProps)(OrderInfo))