/*
    Created on : 15.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import './Header.scss'
import {connect} from 'react-redux'
import {fetchMainMenuCounts, getMainMenuCounts} from '../../store/actions/mainMenu'

class Header extends Component {

    state = {
        cartTotal: 0
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const cartTotal = nextProps.cartTotal
        this.setState({ cartTotal })
    }

    componentDidMount(){
        if(this.props.needupdate){
            this.props.fetchMainMenuCounts()
        } else {
            this.props.getMainMenuCounts()
        }
    }

    render(){
        return(
            <div className="header">
                <div className="wrapper dflex">
                    <div className="logo">
                        <a href="/" className="logo_link">
                            <img src={require('../../image/images/ecobar_352x124_onliner_.png')} alt="" />
                            {/*<img src={require('../../image/images/logo_ecob_ny_2020.svg')} alt="" />*/}
                        </a>
                        <span className="slogan">Онлайн-гипермаркет экотоваров</span>
                    </div>
                    <div className="phone_and_icons">
                        <span className="phone">МТС, A1: <strong>731-90-90</strong></span>
                        <a href="/cart" className="icon icon_cart">
                            <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  viewBox="0 0 420 397.6" style={{enableBackground:'new 0 0 420 397.6'}}>
                                <g>
                                    <path d="M166,312.7c-23.4,0-42.5,19.1-42.5,42.5c0,23.4,19.1,42.5,42.5,42.5c23.4,0,42.4-19.1,42.5-42.5v0
                                        C208.5,331.7,189.4,312.7,166,312.7z M188.5,355.1c0,12.4-10.1,22.5-22.5,22.5c-12.4,0-22.5-10.1-22.5-22.5
                                        c0-12.4,10.1-22.5,22.5-22.5C178.4,332.7,188.5,342.7,188.5,355.1z"/>
                                    <path d="M312.2,312.7c-23.4,0-42.5,19.1-42.5,42.5c0,23.4,19.1,42.4,42.5,42.5h0c0,0,0,0,0,0c11.3,0,22-4.4,30-12.4
                                        c8-8,12.4-18.7,12.4-30c0-11.3-4.4-22-12.4-30C334.2,317.1,323.5,312.7,312.2,312.7z M312.2,332.7c12.4,0,22.5,10.1,22.5,22.5
                                        c0,6-2.3,11.6-6.6,15.9c-4.2,4.2-9.9,6.6-15.9,6.6c-12.4,0-22.5-10.1-22.5-22.5C289.7,342.8,299.8,332.7,312.2,332.7z"/>
                                    <path d="M418.1,64.8c-1.9-2.6-4.9-4.2-8.1-4.2H101.2l-3.5-22.2C94.2,16.1,75.4,0,52.9,0c-0.1,0-0.1,0-0.2,0H10C4.5,0,0,4.5,0,10
                                        s4.5,10,10,10h42.7c0,0,0.1,0,0.1,0c12.6,0,23.2,9,25.1,21.5L111.7,255c3.4,22.3,22.3,38.4,44.8,38.4c0.1,0,0.1,0,0.2,0h208.9
                                        c5.5,0,10-4.5,10-10s-4.5-10-10-10H156.7c0,0-0.1,0-0.1,0c-12.4,0-23.2-9.2-25.1-21.5l-5.2-32.8h210.5c19.4,0,36.7-12.3,43-30.6
                                        l39.7-114.6C420.5,70.8,420,67.5,418.1,64.8z M395.9,80.6l-35.1,101.3c-3.6,10.3-13.3,17.2-24.1,17.2H123.1L104.3,80.6L395.9,80.6z
                                        "/>
                                </g>
                            </svg>
                            { this.state.cartTotal
                                ? <span className="counter_in_cart">{ this.state.cartTotal }</span>
                                : null
                            }
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        cartTotal: state.mainMenu.counts.cartTotal,
        needupdate: state.mainMenu.needupdate
    }
}

function mapDispatchToProps(dispatch){
    return {
        fetchMainMenuCounts: callback => dispatch(fetchMainMenuCounts(callback)),
        getMainMenuCounts: () => dispatch(getMainMenuCounts())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)