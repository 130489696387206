/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/

import axios from 'axios'
import {
	FETCH_BONUS_START, 
	FETCH_BONUS_SUCCESS, 
	FETCH_BONUS_ERROR, 
	GET_BONUS
} from './actionTypes'

export function fetchBonus(page = 1){
	return async dispatch => {
		dispatch(fetchBonusStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		try{
			const responce = await axios.get(testUrl + '/index.php?route=account/account/ecobonus&app_test=1&page=' + page)
			if(responce){
				console.log('fetchBonus', responce)
				const info = {
					bonusInfo: responce.data,
					// bonusInfo:{
					// 	current_customer: responce.data.current_customer,
					// 	customer_group_id: responce.data.customer_group_id,
					// 	customer_group_info: responce.data.customer_group_info,
					// 	all_customer_groups: responce.data.all_customer_groups,
					// 	config_bonus_days: responce.data.config_bonus_days,
					// 	config_bonus_group_1: responce.data.config_bonus_group_1,
					// 	config_bonus_group_2: responce.data.config_bonus_group_2,
					// 	config_bonus_group_3: responce.data.config_bonus_group_3,
					// 	config_bonus_group_4: responce.data.config_bonus_group_4,
					// 	rewards: responce.data.rewards,
					// 	results: responce.data.results,
					// 	total_rewards: responce.data.total_rewards,
					// 	reward_total: responce.data.reward_total,
					// 	awaiting_reward_total: responce.data.awaiting_reward_total,
					// 	bonus_group: responce.data.bonus_group,
					// 	bonus_days: responce.data.bonus_days,
					// 	discount: responce.data.discount,
					// 	raznica: responce.data.raznica,
					// 	zakazov_na_summu: responce.data.zakazov_na_summu
					// },
					needupdate: false
				}
				dispatch(fetchBonusSuccess(info))
			}
		} catch(e){
			console.log(e)
			dispatch(fetchBonusError(e))
		}
	}
}

export function fetchBonusStart(){
	return {
		type: FETCH_BONUS_START
	}
}
export function fetchBonusSuccess(info){
	return {
		type: FETCH_BONUS_SUCCESS,
		bonusInfo: info.bonusInfo,
		needupdate: info.needupdate
	}
}
export function fetchBonusError(e){
	return {
		type: FETCH_BONUS_ERROR,
		error: e
	}
}

export function getBonus(){
	return {
		type: GET_BONUS
	}
}


