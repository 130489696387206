/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/

import axios from 'axios'
import {
	SEND_PASS_INFO_START,
	SEND_PASS_INFO_SUCCESS,
	SEND_PASS_INFO_ERROR, SEND_RESET_SUCCESS,
} from './actionTypes'

export function sendPassInfoData(passInfoData, callback = () => {}){
	return async dispatch => {
		dispatch(sendPassInfoStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}
		
		try{
			const responce = await axios.post(testUrl + '/index.php?route=account/account/changepassword&app_test=1', passInfoData)
			if(responce.status === 200){
				console.log('change password', responce.data)
				const info = {
					success: responce.data.success?responce.data.success:null,
					error: responce.data.error?responce.data.error:null,
				}
				dispatch(sendPassInfoSuccess(info))
				console.log(callback)
				callback()
			} else {
				dispatch(sendPassInfoError({error: "Ошибка связи с сервером"}))
			}
		} catch(e){
			console.log(e)
			dispatch(sendPassInfoError(e))
		}
	}
}

export function sendPassInfoStart(){
	return {
		type: SEND_PASS_INFO_START
	}
}
export function sendPassInfoSuccess(info){
	return {
		type: SEND_PASS_INFO_SUCCESS,
		success: info.success,
		error: info.error
	}
}
export function sendPassInfoError(e){
	return {
		type: SEND_PASS_INFO_ERROR,
		error: e
	}
}


