/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/
import {FETCH_MAIN_MENU_COUNTS_START, FETCH_MAIN_MENU_COUNTS_SUCCESS, FETCH_MAIN_MENU_COUNTS_ERROR, GET_MAIN_MENU_COUNTS} from '../actions/actionTypes'

let initalState = {
	counts: {
		wishProducts: 0,
		orders: 0,
		cartTotal: 0
	},
	loading: false,
	error: null,
	needupdate: true
	
}

export default function mainMenuReducer(state = initalState, action){
	
	switch(action.type){
		case FETCH_MAIN_MENU_COUNTS_START:
			return{
				...state, loading: true
			}
		case FETCH_MAIN_MENU_COUNTS_SUCCESS:

			initalState = { 
				...state, counts: action.counts, needupdate: false
			}
			return{
				...state, loading: false, counts: action.counts, needupdate: false
			}
		case FETCH_MAIN_MENU_COUNTS_ERROR:
			return{
				...state, loading: false, error: action.error
			}
		
		case GET_MAIN_MENU_COUNTS:
			return state
			
		default:
			return state
	}
	
}