/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/
import {
	FETCH_WISH_INFO_START,
	FETCH_WISH_INFO_SUCCESS,
	FETCH_WISH_INFO_ERROR,
	GET_WISH_INFO
} from '../actions/actionTypes'

let initalState = {
	wishProducts:{},
	loading: false,
	error: null,
	needupdate: true
}

export default function wishlistReducer(state = initalState, action){
	
	switch(action.type){
		case FETCH_WISH_INFO_START:
			return{
				...state, loading: true
			}
		case FETCH_WISH_INFO_SUCCESS:

			initalState = { 
				...state, wishProducts: action.wishProducts, needupdate: false
			}
			return{
				...state, loading: false, wishProducts: action.wishProducts, needupdate: false
			}
		case FETCH_WISH_INFO_ERROR:
			return{
				...state, loading: false, error: action.error
			}
		case GET_WISH_INFO:
			return state
			
		default:
			return state
	}
	
}