/* 
    Created on : 10.12.2019, 06:18:59
    Author     : Andy Kar
*/

import {combineReducers} from 'redux'
import authReducer from './auth'
import mainInfoReducer from './mainInfo'
import passInfoReducer from './passInfo'
import addressesReducer from './addresses'
import bonusReducer from './bonus'
import ordersReducer from './orders'
import wishlistReducer from './wishlist'
import maiMenuReducer from './mainMenu'


export default combineReducers({
	auth: authReducer,
	mainInfo: mainInfoReducer,
	passInfo: passInfoReducer,
	addresses: addressesReducer,
	bonus: bonusReducer,
	orders: ordersReducer,
	wishlist: wishlistReducer,
	mainMenu: maiMenuReducer
})


