/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react';
import WishList from '../../components/WishList/WishList';

class WishPage extends Component {
	
	render(){
		return(
			<div>
				<WishList />
			</div>
		)
	}
}

export default WishPage;

