/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react';
import PassInfo from '../../components/PassInfo/PassInfo';

class PasswordPage extends Component {
	
	render(){
		return(
			<div>
				<PassInfo data={this.props.passData}/>
			</div>
		)
	}
}

export default PasswordPage;
