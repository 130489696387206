/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/

import axios from 'axios'
import {
	FETCH_WISH_INFO_START,
	FETCH_WISH_INFO_SUCCESS,
	FETCH_WISH_INFO_ERROR,
	GET_WISH_INFO,
} from './actionTypes'

export function fetchWishInfo(callback = () => {}){
	return async dispatch => {
		dispatch(fetchWishInfoStart())

		let testUrl = ''
		if(window.location.host=== 'localhost:3000'){
			testUrl = 'https://ecobar.by.test'
		}

		const map = {amp: '&', lt: '<', gt: '>', quot: '"', '#039': "'"}
		try{
			const responce = await axios.get(testUrl + '/index.php?route=account/account/wishlist&app_test=1')
			console.log(responce)
			const info = {
				wishProducts: {
					heading_title: responce.data.heading_title,
					text_empty: responce.data.text_empty,
					breadcrumbs: responce.data.breadcrumbs,
					success: responce.data.success,
					products: responce.data.products,
					total_n: responce.data.total_n,
					total: responce.data.total,
					total_sum: responce.data.total_total
				},
				needupdate: false
			}
			Object.keys(responce.data.products).map((product, index) => {
				info.wishProducts.products[index].href = responce.data.products[index].href.replace(/&([^;]+);/g, (m, c) => map[c])
				info.wishProducts.products[index].name = responce.data.products[index].name.replace(/&([^;]+);/g, (m, c) => map[c])
				info.wishProducts.products[index].total_sum = responce.data.products[index].total_sum.replace(/<!--[\s\S]*?--!?>/g, "").replace(/<\/?[a-z][^>]*(>|$)/gi, "")
				info.wishProducts.products[index].price = responce.data.products[index].price.replace(/<!--[\s\S]*?--!?>/g, "").replace(/<\/?[a-z][^>]*(>|$)/gi, "")
				return true
			})

			dispatch(fetchWishInfoSuccess(info))
			callback()
		} catch(e){
			console.log(e)
			dispatch(fetchWishInfoError(e))
		}
	}
}

export function fetchWishInfoStart(){
	return {
		type: FETCH_WISH_INFO_START
	}
}
export function fetchWishInfoSuccess(info){
	return {
		type: FETCH_WISH_INFO_SUCCESS,
		wishProducts: info.wishProducts,
		needupdate: info.needupdate
	}
}
export function fetchWishInfoError(e){
	return {
		type: FETCH_WISH_INFO_ERROR,
		error: e
	}
}
export function getWishInfo(){
	return {
		type: GET_WISH_INFO
	}
}


