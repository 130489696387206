/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button'
import './AddressForm.scss';
import {connect} from 'react-redux'
import {sendNewAddressData, sendAddressData} from '../../store/actions/addresses'
import axios from "axios";


class AddressForm extends Component {
	
	initAddressControls = {
		city:{
			value: '',
			type: 'text',
			placeholder: 'Город',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{
				required: true,
				minLength: 2,
				maxLength: 30
			}
		},
		street:{
			value: '',
			type: 'text',
			placeholder: 'Улица',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{
				// required: true,
				// minLength: 2,
				maxLength: 50
			}
		},
		house:{
			value: '',
			type: 'text',
			placeholder: 'Дом',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{
				required: true
			}
		},
		flat:{
			value: '',
			type: 'text',
			placeholder: 'Квартира',
			errorMessage: '',
			valid: true,
			touched: false,
			validation:{

			}
		}
	}

	state = {
		address: '',
		addressControls: this.initAddressControls,
		cityId: 50232,
		autoCity: [],
		autoStreet: []

	}
	
	formValidate(){
		let formValid = true;
		Object.keys(this.state.addressControls).map((controlName) => {
			const control = this.state.addressControls[controlName]
			if(!control.touched){
				control.valid = this.validateControlError(control.value, control.validation)?false:true
				control.errorMessage = !!this.validateControlError(control.value, control.validation)?this.validateControlError(control.value, control.validation):''	
			}
			if(!control.valid ){
				formValid = false
			}
			return false
		})
		return formValid
	}
	
	saveNewAddress = () => {
		let newAddressData  = new URLSearchParams()
		let newAddress1 = this.state.addressControls['street'].value + ', ' + this.state.addressControls['house'].value  + ', ' + this.state.addressControls['flat'].value
		Object.keys(this.state.addressControls).map((controlName) => {
			const control = this.state.addressControls[controlName]
			newAddressData.append(controlName, control.value)
			return true
		})
		newAddressData.append('address_1', newAddress1)
		this.props.sendNewAddressData(newAddressData)
		
		this.setState({
			addressControls: this.initAddressControls
		})
		
		return true
	}
	
	saveAddress = address_id => {
		let addressData  = new URLSearchParams()
		let address1 = this.state.addressControls['street'].value + ', ' + this.state.addressControls['house'].value  + ', ' + this.state.addressControls['flat'].value
		Object.keys(this.state.addressControls).map((controlName) => {
			const control = this.state.addressControls[controlName]
			addressData.append(controlName, control.value)
			return true
		})
		addressData.append('address_1', address1)
		this.props.sendAddressData(address_id, addressData)
		
		this.props.cancelAddAddresForm()
		
		return true
	}
	
	validateControlError(value, validation){
		if(!validation){
			return false
		}
		
		if(validation.required && value.trim() === ''){
			return "Поле не может быть пустым"
		}
		
		if(validation.minLength && value.length < validation.minLength){
			return "Некорректное значение поля"
		}
		
		if(validation.maxLength && value.length > validation.maxLength){
			return "Некорректное значение поля"
		}
		
		return false
	}

	onChangeHandler = (event, controlName) => {
		
		let addressNew={
			city: this.state.addressControls['city'].value,
			street: this.state.addressControls['street'].value,
			house: this.state.addressControls['house'].value,
			flat: this.state.addressControls['flat'].value
		}

		const addressControls = { ...this.state.addressControls }
		const control = { ...addressControls[controlName] }
		
		control.value = event.target.value
		control.touched = true
		control.valid = !this.validateControlError(control.value, control.validation)
		control.errorMessage = !!this.validateControlError(control.value, control.validation)?this.validateControlError(control.value, control.validation):''

		addressControls[controlName] = control
		
		addressNew[controlName] = control.value

		const address = Object.values(addressNew).join(', ')

		this.setState({ addressControls, address })
		
	}

	async autoCompeteHandler(event){
		const val = event.target.value
		const name = event.target.name

		if(val.length > 2) {
			let valData  = new URLSearchParams()
			valData.append('thisVal', val)
			valData.append('cityId', this.state.cityId)

			let testUrl = ''
			if (window.location.host === 'localhost:3000') {
				testUrl = 'https://ecobar.by.test'
			}

			try {
				const responce = await axios.post(testUrl + `/index.php?route=api/login/get${name}autocomplete`, valData)
				console.log('autoCompeteHandler', responce)
				if(name === 'city') this.setState({autoCity: responce.data.result})
				if(name === 'street') this.setState({autoStreet: responce.data.result})
			} catch (e) {
				console.log(e)
			}
		}

	}

	setNewCity = (cityName, id) => {
		const addressControls = {...this.state.addressControls}
		addressControls.city.value = cityName
		const cityId = id
		this.setState({addressControls, autoCity: [], cityId})

	}
	setNewStreet = (streetName) => {
		const addressControls = {...this.state.addressControls}
		addressControls.street.value = streetName
		this.setState({addressControls, autoStreet: []})

	}

	renderInputs = () => {
		const addressControls = Object.keys(this.state.addressControls).map((controlName, index) => {
			const control = this.state.addressControls[controlName]
			if(controlName !== 'city' && controlName !== 'street') {
				return (
					<Input
						key={controlName + index}
						type={control.type}
						name={controlName}
						value={control.value}
						valid={control.valid}
						placeholder={control.placeholder}
						touched={control.touched}
						shouldValidate={!!control.validation}
						required={control.validation.required}
						errorMessage={control.errorMessage}
						onChange={event => this.onChangeHandler(event, controlName)}
					/>
				)
			} else if(controlName === 'city'){
				return (
					<div className="js_geihelper_list">
						<Input
							autocomplete={"new-password"}
							key={controlName + index}
							type={control.type}
							name={controlName}
							value={control.value}
							valid={control.valid}
							placeholder={control.placeholder}
							touched={control.touched}
							shouldValidate={!!control.validation}
							required={control.validation.required}
							errorMessage={control.errorMessage}
							onChange={event => {
								this.onChangeHandler(event, controlName)
								this.autoCompeteHandler(event)
							}}
						/>
						{this.state.autoCity.length
							? <ul className="geohelper_list">
								{this.state.autoCity.map((city) => <li key={city.id} onClick={() => this.setNewCity(city.name, city.id)} className="js_click_city_select">{city.name}</li>)}
							</ul>
							: null
						}
					</div>
				)
			} else if(controlName === 'street'){
				return (
					<div className="js_geihelper_list">
						<Input
							autocomplete={"new-password"}
							key={controlName + index}
							type={control.type}
							name={controlName}
							value={control.value}
							valid={control.valid}
							placeholder={control.placeholder}
							touched={control.touched}
							shouldValidate={!!control.validation}
							required={control.validation.required}
							errorMessage={control.errorMessage}
							onChange={event => {
								this.onChangeHandler(event, controlName)
								this.autoCompeteHandler(event)
							}}
						/>
						{this.state.autoStreet.length
							? <ul className="geohelper_list">
								{this.state.autoStreet.map((street) => <li key={street.id} onClick={() => this.setNewStreet(street.name)} className="js_click_city_select">{street.name}</li>)}
							</ul>
							: null
						}
					</div>
				)
			}
		})
		
		return addressControls
	}
	
	componentDidMount(){
		if(this.props.address_id){
			const address = this.props.address
			let city = ''
			if(address.split(',', 4).length > 0) {
				city = address.split(',', 4)[0].trim()
			}

			let street = ''
			if(address.split(',', 4).length > 1) {
				street = address.split(',', 4)[1].trim()
			}

			let house = ''
			if(address.split(',', 4).length > 2) {
				house = address.split(',', 4)[2].trim()
			}
			let flat = ''
			if(address.split(',', 4).length > 3){
				flat = address.split(',', 4)[3].trim()
			}

			const addressControls = {
				city:{
					value: city,
					type: 'text',
					placeholder: 'Город',
					errorMessage: '',
					valid: true,
					touched: false,
					validation:{
						required: true,
						minLength: 2,
						maxLength: 30
					}
				},
				street:{
					value: street,
					type: 'text',
					placeholder: 'Улица',
					errorMessage: '',
					valid: true,
					touched: false,
					validation:{
						// required: true,
						// minLength: 2,
						maxLength: 50
					}
				},
				house:{
					value: house,
					type: 'text',
					placeholder: 'Дом',
					errorMessage: '',
					valid: true,
					touched: false,
					validation:{
						required: true
					}
				},
				flat:{
					value: flat,
					type: 'text',
					placeholder: 'Квартира',
					errorMessage: '',
					valid: true,
					touched: false,
					validation:{

					}
				}
			}
			
			this.setState({ addressControls, address })
		}
	}
	
	renderNewAddressActionButton(){
		return (
			<div className="button-group">
				{ this.formValidate()
				? <Button
					type="button"
					icon="SaveOutlined"
					label=""
					classes={['icon-button']}
					onClick ={this.saveNewAddress}
				 />	
				: null
				}
			</div>
		)
	}
	
	addressIsChange(){
		if(this.props.address && this.state.address){

			return this.props.address !== this.state.address
		}
		return false
	}
	
	renderAddressActionButton(){
		return (
			<div className="button-group">
				<Button
					type="button"
					label=""
					icon="RotateLeft"
					classes={['icon-button']}
					onClick ={this.props.cancelAddAddresForm}
				/>
				{ this.addressIsChange() && this.formValidate()
					? <Button
						type="button"
						icon="SaveOutlined"
						label=""
						classes={['icon-button']}
						onClick={this.saveAddress.bind(this, this.props.address_id)}
					  />
					: null
				}
			</div>
		)
	}
	
	render(){
		return(
			<div className="new-address">
				{ !this.props.address_id
					? <h3>Новый адрес</h3>
					: null
				}
				<form id={'formaddaddress' + this.props.address_id} name={'formaddaddress' + this.props.address_id} method="post" encType="multipart/form-data" className="form-horizontal">
					<div className="fieldset">
						{ this.renderInputs() }

						{ !this.props.address_id
							? this.renderNewAddressActionButton()
							: this.renderAddressActionButton()
						}
					</div>
				</form>
			</div>
		)
	}
}


function mapDispatchToProps(dispatch){
	return {
		sendNewAddressData: newAddressData => dispatch(sendNewAddressData(newAddressData)),
		sendAddressData: (address_id, addressData) => dispatch(sendAddressData(address_id, addressData))
	}
}

export default connect(null, mapDispatchToProps)(AddressForm)

