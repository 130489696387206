/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/
import {
	FETCH_BONUS_START,
	FETCH_BONUS_SUCCESS, 
	FETCH_BONUS_ERROR, 
	GET_BONUS} from '../actions/actionTypes'

let initalState = {
	bonusInfo: {},
	loading: false,
	error: null,
	needupdate: true
	
};

export default function bonusReducer(state = initalState, action){
	
	switch(action.type){
		case FETCH_BONUS_START:
			return{
				...state, loading: true
			}
		case FETCH_BONUS_SUCCESS:

			initalState = { 
				...state, bonusInfo: action.bonusInfo, needupdate: false
			}
			return{
				...state, loading: false, bonusInfo: action.bonusInfo, needupdate: false
			}
		case FETCH_BONUS_ERROR:
			return{
				...state, loading: false, error: action.error
			}

		case GET_BONUS:
			return state
			
		default:
			return state
	}
	
}