/* 
    Created on : 29.01.2020, 06:18:59
    Author     : Andy Kar
*/
import {
	FETCH_ADDRESSES_START,
	FETCH_ADDRESSES_SUCCESS, 
	FETCH_ADDRESSES_ERROR, 
	SEND_NEW_ADDRESS_START, 
	SEND_NEW_ADDRESS_ERROR, 
	SEND_ADDRESS_START, 
	SEND_ADDRESS_ERROR, 
	GET_ADDRESSES,
	DELETE_ADDRESS_SUCCESS,
	SET_ADDRESS_DEFAULT_SUCCESS
} from '../actions/actionTypes'

let initalState = {
	
	addresses:{},
	loading: false,
	error: null,
	needupdate: true
	
};

export default function addressesReducer(state = initalState, action){
	
	switch(action.type){
		case FETCH_ADDRESSES_START:
			return{
				...state, loading: true
			}
		case FETCH_ADDRESSES_SUCCESS:

			initalState = { 
				...state, addresses: action.addresses, needupdate: false
			}
			return{
				...state, loading: false, addresses: action.addresses, needupdate: false
			}
		case FETCH_ADDRESSES_ERROR:
			return{
				...state, loading: false, error: action.error
			}
		case SEND_NEW_ADDRESS_START:
			return{
				...state, loading: true
			}
		case SEND_NEW_ADDRESS_ERROR:
			return{
				...state, loading: false, error: action.error
			}
		case SEND_ADDRESS_START:
			return{
				...state, loading: true
			}
	
		case SEND_ADDRESS_ERROR:
			return{
				...state, loading: false, error: action.error
			}
		case DELETE_ADDRESS_SUCCESS:
			return{
				...state, needupdate: true
			}
			
		case SET_ADDRESS_DEFAULT_SUCCESS:
			return{
				...state, needupdate: true
			}
			
		case GET_ADDRESSES:
			return state
			
		default:
			return state
	}
	
}