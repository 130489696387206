/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
import React, {Component} from 'react'
import Auth from '../../components/Auth/Auth'

class AuthPage extends Component {
	
	render(){
		return(
			<div className={'auth'}>
				<Auth />
			</div>
		)
	}
}

export default AuthPage;
